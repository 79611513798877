import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'nai-clinical-severity',
  standalone: true,
  imports: [CommonModule, MatDialogModule, DynamicIconDirective, TranslateModule],
  templateUrl: './clinical-severity.component.html',
  styleUrls: ['./clinical-severity.component.scss'],
})
export class ClinicalSeverityComponent {}
