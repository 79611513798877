export interface FormativeAreaInfo {
  'NANDA-code': string;
  'NANDA-title': string;
  'NANDA-desc': string;
  'NANDA-image': string;
  'NANDA-blocked?'?: boolean;
  'NANDA-new?'?: boolean;
  'NANDA-total-articles': number;
  'NANDA-total-articles-read': number;
}
export interface FormativeAreaInfoResponse {
  'formative-areas': FormativeAreaInfo[];
}
export interface FormativeAreaResponse {
  'formative-area': FormativeAreaInfo;
  'formative-area-articles': Article[];
}

export interface ArticlesResponse {
  'formative-articles': Article[];
}
export interface ArticleResponse {
  'formative-article': Article;
  'suggested-articles': Article[];
}
export interface Article {
  'NANDA-code': string;
  codigo: string;
  titulo: string;
  resumen: string;
  image: {
    title: string;
    link: string;
  };
  'read?': boolean;
  'like?': boolean;
  'new?': boolean;
  'blocked?': boolean;
  'video-duration': number;
  'publication-date': Date;
  'last-update': Date | null;
  'compressibility-level': number;
  'show-in-area-page?': boolean;
  'read-time': string;
  author: string;
  rating: number;
  'formative-media': 'video' | 'text';
  'formative-media-link'?: string;
  "order"?: number,
  contenido?: string;
  figura_portada?: {
    titulo: string;
    link: string;
  };
  actividad_documental?: {
    'author-identifier': string;
    'author-image': {
      mobile: string;
      desktop: string;
    };
    'author-role': string;
  };
  objetivos_contenido?: {
    obj_1: string;
  };
  bibliografia?: Array<{
    titulo: string;
    link: string;
  }>;
  figuras?: {
    leyenda_1: string;
    link_1: string;
  };
  fecha_revision?: {
    ultima_revision: string;
    revisiones: number;
    periodicidad: string;
  };
  relatedArticles?: string[];
}


export interface ArticleResource {
  ubikare?: string;
  type: 'Video' | 'Audio' | 'Image' | 'Página Web';
  code: string;
  'article-code': string;
  title: string;
  link: string;
  duration: string;
  'background-image'?: string;
}

export interface ArticleResourceResponse {
  'article-resources': ArticleResource[];
}

export interface GlossaryWord {
  word: string;
  meanings: string[];
}

export interface GlossaryWordResponse {
  'glossary-word-requested': GlossaryWord;
}
