import { NgIf, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';

@Component({
    selector: 'nai-dialog-success',
    template: `
    <div [ngClass]="data.hasDialogClose ? 'dialog dialog-close' : 'dialog'">
      <button *ngIf="data.hasDialogClose" [mat-dialog-close] class="button button--icon-close">
        <span naiDynIcon [path]="'assets/icons/close.svg'"></span>
      </button>
      <mat-dialog-content>
        <figure>
          <img src="assets/icons/ok-check.svg" />
        </figure>
        <h1 *ngIf="data.title">{{ data.title }}</h1>
        <p *ngIf="data.subtitle">
          {{ data.subtitle }}
        </p>
        <button *ngIf="data.buttonText" [mat-dialog-close] class="button button--outline button--primary">
          {{ data.buttonText }}
        </button>
      </mat-dialog-content>
    </div>

  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./dialog-success.component.scss'],
    standalone: true,
    imports: [MatDialogModule, NgIf, DynamicIconDirective, NgClass],
})
export class DialogSuccessComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { hasDialogClose?: boolean, title?: string; subtitle?: string; buttonText?: string }
  ) {}
}
