import { Injectable } from '@angular/core';
import { DGIResult, DGIResultArea } from '@nai-libs/data-access';
@Injectable({
  providedIn: 'root',
})
export class DGIService {
  countEvaluated(dgiResult: DGIResult): number {
    let count = 0;
    dgiResult.areas.forEach((area: DGIResultArea) => {
      if (area['sub-caps'] && area['sub-caps'].length > 0) {
        count += area['sub-caps'].length;
      } else {
        count++;
      }
    });
    return count;
  }
  countWarnings(dgiResult: DGIResult): number {
    let count = 0;
    dgiResult.areas.forEach((area: DGIResultArea) => {
      if (area['sub-caps'] && area['sub-caps'].length > 0) {
        area['sub-caps'].forEach((subcap: DGIResultArea) => {
          if (subcap['background-color'] === 'red') {
            count++;
          }
        });
      } else if (area['background-color'] === 'red') {
        count++;
      }
    });
    return count;
  }
}
