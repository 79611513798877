export interface Notifications {
  home: number;
  notifications: number;
  care: number;
  services: number;
  help: number;
}
export type NotificationStatus = 'read' | 'unread';
export interface Notification {
  id: string;
  receiver: number;
  title: string;
  description: string; // HTML
  links?: {
    url: string,
    text: string
  }[];
  datetime: string;
  status: NotificationStatus;
  type: string;
  'groupal-interventions-data'?: {
    id: number;
    'group-id': string;
    'periodicity-id': string;
    state: 'declined' | 'waiting' | 'accepted';
  };
}

export interface NotificationHistory {
  page: number;
  'number-of-pages': number;
  types: {
    value: string;
    label: string;
    icon: string;
  }[];
  list: Notification[];
}
