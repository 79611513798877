/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserSelectors } from '@nai-libs/user/data-access';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'nai-new-consultation-success',
    template: `
    <ion-content>
      <div class="page">
        <div class="wrapper-fluid">
          <figure>
            <img src="assets/icons/ok-check.svg" />
          </figure>
          <h1 class="font-headline-4">{{'FORM.SUCCESS.TITLE' | translate }}</h1>
          <p class="font-body-1">{{'FORM.SUCCESS.DESCRIPTION_2' | translate }}</p>
          <a [routerLink]="['/help/chats']" [mat-dialog-close] class="button button--outline button--primary">{{'FORM.SUCCESS.CTA' | translate }}</a>
        </div>
      </div>
    </ion-content>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./new-consultation-success-dialog.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        RouterLink,
        MatDialogModule,
        TranslateModule
    ],
})
export class NewConsultationSuccessDialogComponent {
  user$ = this.store.select(UserSelectors.selectUser);
  constructor(private store: Store) {}
}
