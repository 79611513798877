import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '@nai-libs/standalone/header';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';
import { map } from 'rxjs';
import {
  EdercareActions,
  EdercareSelectors,
  EdercareService,
} from '@nai-libs/edercare/data-access';
import { Store } from '@ngrx/store';
import { HelpComponent } from '../../../../edercare-feature/src/lib/components/help.component';
import { ServiceCardComponent } from '../components/service-card.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SwiperModule } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { UserSelectors } from '../../../../../../user/data-access/src/index';

import { Service } from '@nai-libs/shared/data-access/src';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
@Component({
  selector: 'nai-service-cart-success-dialog',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    DynamicIconDirective,
    HeaderComponent,
    HelpComponent,
    ServiceCardComponent,
    SwiperModule,
    MatDialogModule,
  ],
  template: `
    <ion-content class="">
      <div class="page wrapper-fluid">
        <header class="cart__header">
          <img [src]="'/assets/icons/alert-success.svg'" />
          <h1 *ngIf="user$ | async as user" class="font-headline-2 cart__title">
            {{ user['logged-user'].identifier.name}} {{ user['logged-user'].identifier['first-surname']}}
          </h1>
          <p class="cart__subtitle">Hemos recibido tu solicitud</p>
        </header>
        <div class="cart__message">
          <h2 class="font-headline-5">
            Nos pondremos en contacto lo antes posible
          </h2>
          <p>
            Edercare debe <strong>aprobar esta solicitud</strong> para hacer
            efectiva la contratación. Te contactaremos para confirmar.
          </p>
          <p>
            Cualquier consulta puedes hacerlo escribiendo a
            <a>info@edercare.es</a> o llamando a <a>91 961 90 60</a>.
          </p>
        </div>
        <div class="service-wrapper">
          <swiper
            class="cart__swiper"
            [config]="swiperConfig"
            *ngIf="isTablet$ | async; else noSwiper"
          >
            <ng-template swiperSlide *ngFor="let service of data.cart">
              <nai-service-card
                [service]="service"
                [serviceReceiver]="serviceReceiver$ | async"
                [editable]="false"
                [deletable]="false"
              ></nai-service-card>
            </ng-template>
          </swiper>
          <ng-template #noSwiper>
            <div class="cart__card-container">
              <nai-service-card
                *ngFor="let service of data.cart"
                [serviceReceiver]="serviceReceiver$ | async"
                [editable]="false"
                [service]="service"
              ></nai-service-card>
            </div>
          </ng-template>
        </div>
        <div class="cart__actions-wrapper">
          <button
            mat-dialog-close
            class="button button--edercare button--fill"
            [routerLink]="this.edercareService.getRoute('service')"
          >
            Seguir solicitando
          </button>
        </div>
      </div>
    </ion-content>
  `,
  styleUrls: ['./service-cart-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCartSuccessDialogComponent {
  user$ = this.store.select(UserSelectors.selectUser);
  serviceReceiver$ = this.store.select(UserSelectors.selectServiceReceiver);
  cart$ = this.store.select(EdercareSelectors.selectCart);

  nCartItems$ = this.cart$.pipe(map((cart) => cart?.length));

  isTablet$ = this.breakpointObserver.observe('(min-width: 576px)').pipe(
    map(({ matches }) => {
      return matches;
    })
  );

  swiperConfig: SwiperOptions = {
    freeMode: true,
    spaceBetween: 16,
    slidesPerView: 'auto',
    centeredSlides: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { cart: Service[] },
    private store: Store,
    private breakpointObserver: BreakpointObserver,
    public edercareService: EdercareService
  ) {
    this.loadData();
  }

  loadData() {
    this.store.dispatch(EdercareActions.loadCart());
  }
}
