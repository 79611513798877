export type AppointmentType =
  | 'videoconsultation'
  | 'face-to-face'
  | 'day-center'
  | 'office'
  | 'health-center';
export type AppointmentStatus = 'passed' | 'active' | 'upcoming' | '';
export type AppointmentMoment = 'night' | 'day';
export type AppointmentGroup = '1' | '2' | '3';
export type AppointmentSubGroup =
  | '2.1'
  | '2.2'
  | '2.3'
  | '3.1'
  | '3.2'
  | '3.3'
  | '3.4'
  | '3.5'
  | '3.6';

export interface UpcomingAppointmentsResponse {
  'upcoming-appointments': Appointment[];
}

export interface Appointment {
  'intervention-eid': number;
  start: string;
  end: string;
  type: AppointmentType;
  group: AppointmentGroup;
  'sub-group'?: string;
  description?: string;
  'attendee-state'?: 'accepted' | 'declined' | 'waiting';
  'attendee-eid'?: number;
  'attendees-count'?: number;
  'service-name': string;
  'prof-role': string;
  'prof-eid'?: number;
  'prof-identifier'?: string;
  'meet-link'?: string;
  reference?: 'nurse' | 'commercial' | 'mediator' | null;
  'prof-img-link'?: {
    desktop: string;
    mobile: string;
  };
  frequency?: string;
  done?: boolean;
  consult?: boolean;
  registered?: boolean;
  'prof-reference'?: 'nurse' | 'commercial' | 'mediator' | null;
  place?: {
    link: string;
    title: string;
  };
  'prof-image'?: string;
}
export interface AppointmentsByMonth {
  month: string;
  year: string;
  'scheduled-appointments': Appointment[];
}

export interface VideoCallsResponse {
  videoconsultations: Appointment[];
}
