import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ArticleState, ARTICLE_REDUCER_KEY } from './article.reducer';

export const selectArticleState =
  createFeatureSelector<ArticleState>(ARTICLE_REDUCER_KEY);

export const selectArticleStatusPending = createSelector(
  selectArticleState,
  (state) => state.articleStatus.pending
);
export const selectArticles = createSelector(
  selectArticleState,
  (state) => state.articles
);

export const selectSuggestedArticles = createSelector(
  selectArticleState,
  (state) =>
    state.articles.filter((article) =>
      state.suggestedArticles.includes(article.codigo)
    )
);
export const selectSuggestedArticlesPending = createSelector(
  selectArticleState,
  (state) => state.suggestedArticlesStatus.pending
);

export const selectFormativeAreas = createSelector(
  selectArticleState,
  (state) => state.areaInfoList
);
export const selectAreaInfoListPending = createSelector(
  selectArticleState,
  (state) => state.areaInfoListStatus.pending
);

export const selectAreaInfo = (id: string) =>
  createSelector(selectArticleState, (state) =>
    state.areaInfoList?.find((area) => area['NANDA-code'] === id)
  );
export const selectAreaArticles = (id: string, isAreaPage: boolean) =>
  createSelector(selectArticleState, (state) =>
    state.articles.filter((article) => article['NANDA-code'] === id && (!isAreaPage || article['show-in-area-page?']))
  );
export const selectArticle = (id: string) =>
  createSelector(selectArticleState, (state) =>
    state.articles.find((article) => article.codigo === id)
  );
export const selectResources = (id: string) =>
  createSelector(selectArticleState, (state) =>
    state.resources.filter((resource) => resource['article-code'] === id)
  );
export const selectRelatedArticles = (id: string) =>
  createSelector(selectArticleState, (state) => {
    const selectedArticle = state.articles.find(
      (article) => article.codigo === id
    );
    return state.articles.filter((article) =>
      selectedArticle?.relatedArticles?.includes(article.codigo)
    );
  });

export const selectGlossaryWordStatus = createSelector(
  selectArticleState,
  (state) => state.glossaryWordStatus
);
export const selectGlossaryWord = (word: string) =>
  createSelector(selectArticleState, (state) =>
    state.glossaryWords?.find((glossary) => glossary.word === word)
  );
