import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MedicationState } from './medication.reducer';
import { MEDICATION_REDUCER_KEY } from './medication.reducer';

export const selectMedicationState = createFeatureSelector<MedicationState>(
  MEDICATION_REDUCER_KEY
);

export const selectMedication = createSelector(
  selectMedicationState,
  (state) => state.medication
);
