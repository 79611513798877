<button [mat-dialog-close] class="button button--icon-close">
    <span naiDynIcon [path]="'assets/icons/close.svg'"></span>
  </button>
  <mat-dialog-content class="dialog-goal">
    <figure class="dialog-alert__figure">
      <img [src]="'/assets/icons/alert-warning.svg'" />
    </figure>
    <header class="dialog-alert__header">
      <p class="dialog-alert__header-heading font-body-2">
       {{ 'CLINICAL_SEVERITY.DIALOG.TITLE' | translate }}
      </p>
      <p class="dialog-alert__detail font-body-1">
        {{ 'CLINICAL_SEVERITY.DIALOG.DESCRIPTION' | translate }}</p>
    </header>

  </mat-dialog-content>