import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@nai-libs/app-config';
import {
    AppConfig, Goal, GoalItem, GoalsByMonth, ServiceReceiver
} from '@nai-libs/data-access';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { DateUtilsService } from '@nai-libs/utility';

@Injectable({ providedIn: 'root' })
export class GoalService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private dateUtils: DateUtilsService
  ) {}

  /**
   * Método para obtener los goals diarios
   */
  getGoals(
    serviceReceiver: ServiceReceiver,
    date: Date,
  ): Observable<GoalItem> {
    const paramsObj: {
      'client-phone': string;
      date: string;
    } = {
      'client-phone': serviceReceiver['client-phone'],
      date: this.dateUtils.dateToYearMonthDayHyphen(date),
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<any>(
        `${this.env.apiUrl}health/health-plan/goals/day?${params}`
      )
      .pipe(
        map((response) => response.goals[0]),
        catchError((err) => throwError(() => err))
      );
  }

/*   getGoals(): Observable<Goal[]> {
    return of(GOALS_DATA);
  } */

  getGoalsByMonth(
    date: Date,
    serviceReceiver: ServiceReceiver,
  ): Observable<GoalsByMonth> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'date': date.getFullYear().toString() + '-' +(date.getMonth() + 1).toString() + '-' +  date.getDay().toString()
    };

    const params = new URLSearchParams(paramsObj);

    return this.http
      .get<GoalsByMonth>(
        `${this.env.apiUrl}health/health-plan/goals/month?${params}`
      )
      .pipe(
        map((response) => response),
        catchError((err) => throwError(() => err))
      );
  }

  /**
   * Método para actualizar el goal
   */
  modifyGoal(
      accept: boolean,
      id: string,
      serviceReceiver: ServiceReceiver
    ) {
      const paramsObj = {
        'client-phone': serviceReceiver['client-phone'],
        'goal-id': id,
        'new-state': accept,
      };

      return this.http.post<{ 'success?': boolean }>(
        `${this.env.apiUrl}health/health-plan/goals/update-goal`,
        paramsObj
      );
  }

}
