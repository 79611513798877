import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertType, AlertTypeIcons } from '@nai-libs/shared/data-access/src';
import { AlertComponent } from '@nai-libs/standalone/alert';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private _snackBar: MatSnackBar) {}

  showAlert(alertType: AlertType = 'info', message: string, duration = 10000, verticalPosition: 'top' | 'bottom' = 'bottom', imageUrl?: string, mode?: 'edercare' | 'default') {
    if(!imageUrl) {
      type ObjectKey = keyof typeof AlertTypeIcons;
      const alertKey = alertType as ObjectKey;
      imageUrl = AlertTypeIcons[alertKey];
    }
  
    this._snackBar.openFromComponent(AlertComponent, {
      duration,
      verticalPosition,
      panelClass: ['alert-snackbar', 'alert-snackbar--' + alertType],
      data: {
        message,
        alertType,
        imageUrl,
        mode
      },
    });
  }
}
