import { Inject, Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, User } from '@nai-libs/data-access';
import {
  UserActions,
  UserSelectors,
  UserService,
} from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';

import {
  Observable,
  catchError,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  isUserLoaded$ = this.store.select(UserSelectors.isUserLoaded);
  isServiceReceiverSelected$ = this.store.select(
    UserSelectors.isServiceReceiverSelected
  );

  constructor(
    private store: Store,
    private userService: UserService,
    private router: Router,
    @Inject(APP_CONFIG) public env: AppConfig
  ) {}

  private _hasAcceptedTerms(user: User) {
    return (
      user['logged-user']['terms-and-conditions-of-use'] &&
      user['logged-user']['privacy-policy']
    );
  }

  private _hasValidToken(user: User) {
    return user['valid-token?'];
  }

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.userService.getToken().pipe(
      switchMap((_) => {
        return this.isUserLoaded$.pipe(
          tap((isUserLoaded) => {
            if (!isUserLoaded)
              this.store.dispatch(UserActions.loadUserData({ isLogin: false }));
          }),
          map((_) => true)
        );
      }),
      catchError((_) => of(true))
    );
  }

  canLoad():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.userService.getToken().pipe(
      switchMap((token) => {
        if (!token) {
          if (this.env.features.edercare) {
            return of(this.router.createUrlTree(['/', 'edercare']));
          } else {
            return of(this.router.createUrlTree(['/', 'login']));
          }
        }
        return this.isUserLoaded$.pipe(
          tap((isUserLoaded) => {
            if (!isUserLoaded) {
              this.store.dispatch(UserActions.loadUserData({ isLogin: false }));
            }
          }),
          filter((isUserLoaded) => !!isUserLoaded),
          mergeMap(() => this.store.select(UserSelectors.selectUser)),
          map((user: User | undefined) => {
            if (!user) return false;
            if (!this._hasValidToken(user)) return false;
            if (!this._hasAcceptedTerms(user))
              return this.router.createUrlTree(['/legal']);
            return true;
          })
        );
      }),
      catchError((_) => {
        if (this.env.features.edercare) {
          return of(this.router.createUrlTree(['/', 'edercare']));
        } else {
          return of(this.router.createUrlTree(['/', 'login']));
        }
      })
    );
  }
}

export const canLoadAuth: CanActivateFn = () => {
  return inject(AuthGuard).canLoad();
};

export const canActivateAuth: CanActivateFn = () => {
  return inject(AuthGuard).canActivate();
};
