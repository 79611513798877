import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FORM_REDUCER_KEY, FormState } from './form.reducer';
import { FormSummary, FormSummary2 } from '@nai-libs/shared/data-access/src';

export const selectFormState =
  createFeatureSelector<FormState>(FORM_REDUCER_KEY);

export const selectForm = (id: string) =>
  createSelector(selectFormState, (state) => {
    return state.forms?.find((form) => form.id == id);
  });

// Informa sobre el estado de completitud del rosco
export const selectFormSummary = (id: string) =>
  createSelector(selectFormState, (state) => {
    return state.formSummaries?.find((form) => form.id == id) as
      | FormSummary
      | undefined;
  });
export const selectFormSummaryStatusPending = createSelector(
  selectFormState,
  (state) => {
    return state.formSummaryStatus.pending;
  }
);

export const selectFormSummary2 = (id: string) =>
  createSelector(selectFormState, (state) => {
    return state.formSummaries?.find((form) => form.id == id) as
      | FormSummary2
      | undefined;
  });

export const selectFormSummary2Editable = (id: string) =>
  createSelector(selectFormState, (state) => {
    return !!(
      state.formSummaries?.find((form) => form.id == id) as FormSummary2
    )?.['editable?'];
  });

export const selectFormSummary2Section = ({
  id,
  sectionSlug,
}: {
  id: string;
  sectionSlug: string;
}) =>
  createSelector(selectFormState, (state) => {
    return (
      state.formSummaries?.find((form) => form.id == id) as
        | FormSummary2
        | undefined
    )?.results.find((section) => section.slug == sectionSlug);
  });

// Da info sobre el progreso del formulario
export const selectFormStatus = (id: string) =>
  createSelector(selectFormState, (state) => {
    return state.formStatuses?.find((status) => status.id == id);
  });
