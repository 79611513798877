import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'nai-summary-item',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div>
      <div class="font-overline title font-grey">{{ title }}</div>
      <div class="font-base content">
        <ng-content></ng-content>
      </div>
    </div>
    <button
      *ngIf="editable"
      class="button button--clear"
      [ngClass]="{
        'button--edercare': style === 'edercare',
    }"
      (click)="clickEdit.emit()"
    >
      Editar
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./summary-item.component.scss'],
})
export class SummaryItemComponent {
  @Output() clickEdit = new EventEmitter();
  @Input() title = '';
  @Input() editable = true;
  @Input() style: 'default' | 'edercare' = 'default';
}
