import { HealthPlanProgress, HealthPlanSummaryItem, Plan, PlanResponse, RequestStatus } from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';
import * as PlanActions from '../+state/plan.actions';

export const PLAN_REDUCER_KEY = 'plan';

export interface PlanState {
  plans?: PlanResponse;
  healthPlanProgress?: HealthPlanProgress[];
  healthPlanSummary?: HealthPlanSummaryItem[];
  plansStatus: RequestStatus;
  setPlanPreferencesStatus: RequestStatus;
}

const initialState: PlanState = {
  plansStatus: { pending: false },
  setPlanPreferencesStatus: { pending: false },
};

export const PlanReducer = createReducer(
  initialState,
  on(PlanActions.loadPlans, (state) => ({
    ...state,
    plansStatus: { pending: true },
  })),
  on(PlanActions.loadPlansSuccess, (state, { plans }) => ({
    ...state,
    plans,
    plansStatus: { pending: false },
  })),
  on(PlanActions.loadPlansFailure, (state) => ({
    ...state,
    plansStatus: { pending: false },
  })),

  on(PlanActions.setPlanPreferences, (state) => ({
    ...state,
    setPlanPreferencesStatus: { pending: true },
  })),
  on(PlanActions.setPlanPreferencesSuccess, (state) => ({
    ...state,
    setPlanPreferencesStatus: { pending: false },
  })),
  on(PlanActions.setPlanPreferencesFailure, (state) => ({
    ...state,
    setPlanPreferencesStatus: { pending: false },
  })),

  on(PlanActions.loadHealthPlanProgress, (state) => ({
    ...state,
    plansStatus: { pending: true },
  })),
  on(PlanActions.loadHealthPlanProgressSuccess, (state, { healthPlanProgress }) => ({
    ...state,
    healthPlanProgress,
    plansStatus: { pending: false },
  })),
  on(PlanActions.loadHealthPlanProgressFailure, (state) => ({
    ...state,
    plansStatus: { pending: false },
  })),

  on(PlanActions.loadHealthPlanSummary, (state) => ({
    ...state,
    plansStatus: { pending: true },
  })),
  on(PlanActions.loadHealthPlanSummarySuccess, (state, { healthPlanSummary }) => ({
    ...state,
    healthPlanSummary,
    plansStatus: { pending: false },
  })),
  on(PlanActions.loadHealthPlanSummarysFailure, (state) => ({
    ...state,
    plansStatus: { pending: false },
  })),
);
