import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DNI_FIRST_LETTERS, DNI_LETTERS, REGEX_DNI } from '@nai-libs/data-access';

export class CustomValidators {
  static mustMatch(
    controlName: string,
    matchingControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value[controlName] !== control.value[matchingControlName]) {
        return { matches: false };
      }
      return null;
    };
  }

  static hasRequirements(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (value) {
        return CustomValidators.isRequiredLength(value) &&
          CustomValidators.hasRequiredChar(value) &&
          CustomValidators.hasRequiredDigit(value)
          ? null
          : { requirements: false };
      }
      return null;
    };
  }

  static isRequiredLength(value: string) {
    return value.length >= 4;
  }

  static hasRequiredChar(value: string) {
    return /[a-zA-Z]/.test(value);
  }

  static hasRequiredDigit(value: string) {
    return /\d/.test(value);
  }

  static isValidDNI(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value.toUpperCase();
    if(!REGEX_DNI.test(value)) return { pattern: true };
  
    const firstChar = value.charAt(0);
    const firstCharIndex = DNI_FIRST_LETTERS.indexOf(firstChar)

    if(firstCharIndex !== -1) value = DNI_FIRST_LETTERS[firstCharIndex] + value.slice(1);

    const lastLetter = value.slice(-1);
    const rest = parseInt(value.slice(0, -1)) % 23;
    return lastLetter === DNI_LETTERS.charAt(rest) ? null : { pattern: true };
    }
  }

  static timeFormat(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return (!control.value || /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(control.value))
        ? null
        : { invalidTimeFormat: true };
    };
  }
}
