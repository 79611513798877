import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  Notification,
  NotificationHistory,
  Notifications,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';

import { DateUtilsService } from '@nai-libs/utility';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private dateUtils: DateUtilsService
  ) {}

  fetchNotifications(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Notifications> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http.get<Notifications>(
      `${this.env.apiUrl}familiar-profile/notifications/fetch-notifications-status?${params}`
    );
  }

  saveReadNotification(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ) {
    const paramsObj = {
      'notification-eid': id,
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http.post(
      `${this.env.apiUrl}familiar-profile/notifications/save-read-notification`,
      paramsObj,
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      }
    );
  }

  fetchNotificationHistory(
    user: User,
    serviceReceiver?: ServiceReceiver,
    selectedUser?: SelectedUser,
    date?: Date,
    type?: string,
    page?: number
  ): Observable<NotificationHistory> {
    const paramsObj: {
      'user-id'?: string;
      'client-phone'?: string;
      'familiar-email': string;
      page: string;
      'filter-date'?: string;
      'filter-type'?: string;
    } = {
      'user-id': selectedUser?.['user-id'],
      'client-phone': serviceReceiver?.['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      page: (page ?? 0).toString(),
    };

    if (date) {
      paramsObj['filter-date'] = this.dateUtils.dateToYearMonthDayHyphen(date);
    }

    if (type) {
      paramsObj['filter-type'] = type;
    }

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<NotificationHistory>(
        `${this.env.apiUrl}familiar-profile/notifications/fetch-notifications-all?${params}`
      )
  }

  modifyNotificationAttendance(
    accept: boolean,
    notification: Notification,
    user: User,
    serviceReceiver: ServiceReceiver
  ) {
    const paramsObj = {
      ...notification,
      'client-phone': serviceReceiver['client-phone'],
      email: user['logged-user']['e-mail'],
      'attendee-state': accept ? 'accepted' : 'declined',
    };
    return this.http.post<{ 'success?': boolean }>(
      `${this.env.apiUrl}familiar-profile/notifications/groupal-intervention-status`,
      paramsObj
    );
  }
}
