import {
  RequestStatus,
  SavedServiceStatus,
  Service,
  ServiceCategory,
  ServiceFilter,
  ServiceInfo,
  ServiceLocationMarker,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';

import * as EdercareActions from './edercare.actions';

export interface EdercareState {
  hireServices?: ServiceCategory[];
  hireServicesStatus: RequestStatus;
  serviceFilter?: ServiceFilter;
  serviceLocations?: ServiceLocationMarker[];
  serviceLocationStatus: RequestStatus;
  cart?: Service[];
  cartStatus: RequestStatus;
  recommendedServices?: ServiceInfo[];
  serviceHistory?: {
    filters: SavedServiceStatus[];
    nTotal: number;
    services: Service[];
  };

  serviceHistoryStatus: RequestStatus;
  serviceDetail?: Service;
  serviceDetailStatus: RequestStatus;
}

const initalState: EdercareState = {
  hireServicesStatus: { pending: false },
  serviceLocationStatus: { pending: false },
  cartStatus: { pending: false },
  serviceHistoryStatus: { pending: false },
  serviceDetailStatus: {pending: false}
};

export const hireServicesReducer = createReducer(
  initalState,
  on(EdercareActions.loadAvailableServices, (state) => ({
    ...state,
    hireServicesStatus: { pending: true },
  })),
  on(
    EdercareActions.loadAvailableServicesSuccess,
    (state, { hireServices }) => ({
      ...state,
      hireServices,
      hireServicesStatus: { pending: false },
    })
  ),
  on(EdercareActions.loadAvailableServicesFailure, (state) => ({
    ...state,
    hireServicesStatus: { pending: false },
  })),
  on(EdercareActions.loadRecommendedServices, (state) => ({
    ...state,
    recommendedServicesStatus: { pending: true },
  })),
  on(EdercareActions.loadRecommendedServicesSuccess, (state, { services }) => ({
    ...state,
    recommendedServices: services,
    recommendedServicesStatus: { pending: false },
  })),
  on(EdercareActions.loadRecommendedServicesFailure, (state) => ({
    ...state,
    recommendedServicesStatus: { pending: false },
  })),

  on(EdercareActions.loadServiceLocationMarkers, (state) => ({
    ...state,
    serviceLocationStatus: { pending: true },
  })),
  on(
    EdercareActions.loadServiceLocationMarkersSuccess,
    (state, { markers }) => ({
      ...state,
      serviceLocations: markers,
      serviceLocationStatus: { pending: false },
    })
  ),

  on(EdercareActions.loadServiceLocationMarkersFailure, (state) => ({
    ...state,
    serviceLocations: undefined,
    serviceLocationStatus: { pending: false },
  })),

  on(EdercareActions.clearServiceLocationMarkers, (state) => ({
    ...state,
    serviceLocations: undefined,
    serviceLocationStatus: { pending: false },
  })),

  on(EdercareActions.setServiceFilterWord, (state, { word }) => {
    return {
      ...state,
      serviceFilter: { ...state.serviceFilter, word },
    };
  }),
  on(EdercareActions.setServiceFilterCategory, (state, { categories }) => {
    return {
      ...state,
      serviceFilter: { ...state.serviceFilter, categories },
    };
  }),

  on(EdercareActions.loadCart, (state) => ({
    ...state,
    cartStatus: { pending: true },
  })),
  on(EdercareActions.loadCartSuccess, (state, { cart }) => ({
    ...state,
    cart,
    cartStatus: { pending: false },
  })),
  on(EdercareActions.loadCartFailure, (state) => ({
    ...state,
    cartStatus: { pending: false },
  })),

  on(EdercareActions.emptyCartSuccess, (state) => ({
    ...state,
    cart: undefined,
  })),
  on(EdercareActions.loadServiceHistory, (state) => ({
    ...state,
    serviceHistoryStatus: { pending: true },
  })),
  on(
    EdercareActions.loadServiceHistorySuccess,
    (state, { services, page, total, filters }) => ({
      ...state,
      serviceHistory: {
        filters,
        nTotal: total,
        services,
      },
      serviceHistoryStatus: { pending: false },
    })
  ),
  on(EdercareActions.loadServiceHistoryFailure, (state) => ({
    ...state,
    serviceHistoryStatus: { pending: false },
  })),
  on(EdercareActions.loadService, (state) => ({
    ...state,
    serviceDetailStatus: { pending: true },
  })),
  on(
    EdercareActions.loadServiceSuccess,
    (state, { service }) => ({
      ...state,
      serviceDetail: service,
      serviceDetailStatus: { pending: false },
    })
  ),
  on(EdercareActions.loadServiceFailure, (state) => ({
    ...state,
    serviceDetailStatus: { pending: false },
  })),
);
