import { Form, FormStatus, FormSummary, FormSummary2, RequestStatus } from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';

import * as FormActions from './form.actions';

export const FORM_REDUCER_KEY = 'form';

export interface FormState {
  forms?: Form[];
  formStatus: RequestStatus;

  formSummaries?: Array<FormSummary | FormSummary2>;
  formSummaryStatus: RequestStatus;

  formStatuses?: FormStatus[];
  formStatusesStatus: RequestStatus;
}

const initalState: FormState = {
  formStatus: { pending: false },
  formSummaryStatus: { pending: false },
  formStatusesStatus: { pending: false },
};

export const formReducer = createReducer(
  initalState,
  on(FormActions.loadForm, (state) => ({
    ...state,
    formStatus: { pending: true },
  })),
  on(FormActions.loadFormSuccess, (state, { form }) => {
    const newForms: Form[] = state.forms
      ? JSON.parse(JSON.stringify(state.forms))
      : [];
    const index = newForms?.findIndex((form) => form.id == form.id);
    if (index >= 0) {
      newForms[index] = form;
    } else {
      newForms.push(form);
    }
    return {
      ...state,
      forms: newForms,
      formStatus: { pending: false },
    };
  }),
  on(FormActions.loadFormFailure, (state) => ({
    ...state,
    formStatus: { pending: false },
  })),

  on(FormActions.saveForm, (state) => ({
    ...state,
    formStatus: { pending: true },
  })),
  on(FormActions.saveFormSuccess, (state) => ({
    ...state,
    formStatus: { pending: false },
  })),
  on(FormActions.saveFormFailure, (state) => ({
    ...state,
    formStatus: { pending: false },
  })),
  on(FormActions.loadFormSummary, (state) => ({
    ...state,
    formSummaryStatus: { pending: true },
  })),
  on(FormActions.loadFormSummarySuccess, (state, { form }) => {
    const newForms: Array<FormSummary | FormSummary2> = state.formSummaries
      ? JSON.parse(JSON.stringify(state.formSummaries))
      : [];
    const index = newForms?.findIndex((form) => form.id == form.id);
    if (index >= 0) {
      newForms[index] = form;
    } else {
      newForms.push(form);
    }
    return {
      ...state,
      formSummaries: newForms,
      formSummaryStatus: { pending: false },
    };
  }),
  on(FormActions.loadFormSummaryFailure, (state) => ({
    ...state,
    formSummaryStatus: { pending: false },
  })),
  on(FormActions.loadFormStatus, (state) => ({
    ...state,
    formStatusesStatus: { pending: true },
  })),
  on(FormActions.loadFormStatusSuccess, (state, { status }) => {
    const newStatus: FormStatus[] = state.formStatuses
      ? JSON.parse(JSON.stringify(state.formStatuses))
      : [];

    const index = newStatus?.findIndex((form) => form.id == status.id);

    if (index >= 0) {
      newStatus[index] = status;
    } else {
      newStatus.push(status);
    }
    return {
      ...state,
      formStatuses: newStatus,
      formStatusesStatus: { pending: false },
    };
  }),
  on(FormActions.loadFormStatusFailure, (state) => ({
    ...state,
    formStatusesStatus: { pending: false },
  }))
);
