import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, RouterLink } from "@angular/router";
import { DynamicIconDirective } from "@nai-libs/directives/dynicon";
@Component({
    selector: 'nai-password-change-success-page',
    template: `
    <div>
      <span naiDynIcon [overrideColor]="false" [path]="'assets/icons/ok-check.svg'"></span>
      <h2>Contraseña cambiada</h2>
      <div class="actions-wrapper">
        <button class="button button--outline button--primary" [routerLink]="['/perfil']" (click)="exit()">Salir</button>
      </div>
    </div>
  `,
    styleUrls: ['./password-change-success-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DynamicIconDirective, RouterLink]
})
export class PasswordChangeSuccessPageComponent {

  constructor(
    private location: Location,
    private dialog: MatDialog,
    private router: Router
  ) { }

  handleBack() {
    this.location.historyGo(-1);
  }

  exit() {
    this.dialog.closeAll();
    this.router.navigate(['/perfil']);
  }
}