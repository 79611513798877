import { DocumentCategory, DocumentsByType } from '@nai-libs/data-access';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DOCUMENT_REDUCER_KEY, DocumentState } from './document.reducer';

export const selectDocumentState =
  createFeatureSelector<DocumentState>(DOCUMENT_REDUCER_KEY);

export const areNewServicesPending = createSelector(
  selectDocumentState,
  (state) => state.newServicesStatus.pending
);
export const selectNewServices = createSelector(
  selectDocumentState,
  (state) => state.newServices
);
export const selectNumServices = createSelector(
  selectDocumentState,
  (state) => state.numServices
);

export const selectPAECHStatusPending = createSelector(
  selectDocumentState,
  (state) => state.PAECHStatus.pending
);
export const selectDocumentsByCategory = (category: DocumentCategory) =>
  createSelector(selectDocumentState, (state) => state.documents?.[category]);

export const signDocumentStatusPending = createSelector(
  selectDocumentState,
  (state) => state.signDocumentStatus.pending
);

export const selectDocumentById = (id: string, type?: DocumentCategory) =>
  createSelector(selectDocumentState, (state) => {
    if (!state.documents) {
      return null;
    }
    if (type) {
      return state.documents?.[type]?.find(
        (iterationDocument) => iterationDocument.id === id
      );
    } else {
      for (const category of Object.keys(state.documents)) {
        const documentsByCategory =
          state.documents[category as DocumentCategory];
        if (documentsByCategory) {
          const document = documentsByCategory.find(
            (iterationDocument) => iterationDocument.id == id
          );

          if (document) {
            return document;
          }
        }
      }
    }
    return null;
  });

export const selectDocuments = createSelector(
  selectDocumentState,
  (state) => state.documents
);
export const selectDocumentCategories = (categories: DocumentCategory[]) =>
  createSelector(selectDocumentState, (state) => {
    const documents: DocumentsByType = {};
    categories.map(
      (category) =>
        state.documents?.[category] &&
        (documents[category] = state.documents[category])
    );
    return documents;
  });

export const selectOpenDocumentPending = createSelector(
  selectDocumentState,
  (state) => state.openDocumentStatus.pending
);

export const selectDocumentLinkReady = createSelector(
  selectDocumentState,
  (state) => state.documentLinkReady
);
