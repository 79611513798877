export interface Consult {
  'consult-topic': string;
  'consult-opener': string[];
  'consult-responsible': ProfessionalAreasText;
  'consult-subtopic': string;
  'consult-FAQ-relation': string[];
  'consult-priority': string;
  'consult-response-max-timeout'?: number;
}
export interface ConsultOpeningRulesTopic {
  'consult-topic': string;
  'consult-list': Consult[];
  'consult-responsible': ProfessionalAreasText;
}

export interface AreaProfessionalRelation {
  'asesor-familiar'?: number;
  'enfermera-referente'?: number;
  'mediadora-familiar'?: number;
  'profesional-referente'?: number;
  'coordinador-gestor'?: number;
  'coordinador-tecnico'?: number;
}

export type ProfessionalAreasSlug =
  | 'asesor-familiar'
  | 'enfermera-referente'
  | 'mediadora-familiar'
  | 'profesional-referente'
  | 'coordinador-gestor'
  | 'coordinador-tecnico';
export type ProfessionalAreasText =
  | 'Asesor Familiar'
  | 'Enfermera Referente'
  | 'Mediadora Familiar'
  | 'Profesional Referente'
  | 'Coordinador Gestor'
  | 'Coordinador Técnico';

export const ProfessionalAreasRelation = {
  'Asesor Familiar': 'asesor-familiar',
  'Enfermera Referente': 'enfermera-referente',
  'Mediadora Familiar': 'mediadora-familiar',
  'Profesional Referente': 'profesional-referente',
};

export interface ConsultOpeningRulesResponse {
  'consult-opening-rules': ConsultOpeningRulesTopic[];
  'area-professional-relation': AreaProfessionalRelation;
}

export interface LastMessage {
  'reg-eid': number;
  date: Date;
  'incidence-reason': string;
  title: string;
  desc: string;
  creator: string;
  'creator-eid': number;
  'creator-image': {
    desktop: string;
    mobile: string;
  };
}

export interface ConsultUser {
  name: string;
  email: string;
}

export interface ConsultReceiverData {
  eid: number;
  name: string;
  email: string;
  'img-link'?: {
    desktop: string;
    mobile: string;
  };
  'complete-img-link'? : string;
  role: string;
  type: string;
}
export interface ConsultComment {
  date: string;
  desc: string;
  creator: string;
  'creator-eid': number;
  'creator-phone': string;
}
export interface ConsultData {
  date: string;
  'receiver-data': ConsultReceiverData[];
  receivers: string[];
  comments: ConsultComment[];
  type: string;
  state: string;
  title: string;
  priority?: string;
  identifier?: string;
  'possible-to-answer-emails': string[];
  'read-by': ConsultUser[];
  opener: string;
  'reg-eid': number;
  responsibles: ConsultUser[];
}

export interface Consult {
  'incidence-reason': string;
  'reg-data': ConsultData;
}
export interface Attendee {
  eid: number;
  'responsible?': boolean;
  email: string;
  phone: string;
}

export interface Videoconsultation {
  attendee: Attendee[];
  start: string;
  type: string;
  'intervention-eid': number;
  title: string;
  reason: string;
  'meet-link': string;
  end: string;
  professional: {
    'prof-eid': number;
    'prof-role': string;
    'prof-identifier': string;
    'prof-reference?': string;
    'consult?': boolean;
    'prof-img-link': {
      desktop: string;
      mobile: string;
    };
  };
  price: string;
}
export interface ConsultHistory {
  'last-message': LastMessage;
  'consultation-list': Consult[];
  'videoconsultations-list': Videoconsultation[];
}

export interface NewVideoconsultation {
  reason?: string;
  'start-date'?: Date;
  'attendee-email'?: string;
  'attendee-phone'?: string;
  price?: number;
}

export interface VideoconsultationAvailability {
  'SEPA?': boolean;
  availability: {
    'professional-eid': number;
    'start-date': Date;
  }[];
  'has-videoconsults?': boolean;
}
