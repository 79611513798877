import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { ModalController, NavController, IonicModule } from '@ionic/angular';
import { APP_CONFIG } from '@nai-libs/app-config';
// import { ClockInSelectors } from "@nai-libs/clock-in/data-access/src";
import { AppConfig } from '@nai-libs/shared/data-access/src';
import { Store } from '@ngrx/store';
import Lottie from 'lottie-web';
import { of } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'nai-clock-in-success-dialog',
    template: `
    <ion-content>
      <div class="dialog dialog--clock-in-success">
        <div class="lottie" #lottieSiginingSuccess></div>
        <p class="heading font-headline-4">Genial</p>
        <p class="hour font-body-1">Hoy a las {{ this.now | date: 'HH:mm' }}</p>
        <!--p *ngIf="!env.production && (lastNFCId$ | async) as id">
          <strong>NFC Id:</strong>{{ id }}
        </p-->
        <div class="actions-wrapper">
          <button
            (click)="closeAndNavigateToHome()"
            class="button button--outline button--primary"
          >
            Salir
          </button>
        </div>
      </div>
    </ion-content>
  `,
    styleUrls: ['./clock-in-success.dialog.scss'],
    standalone: true,
    imports: [IonicModule, DatePipe],
})
export class ClockInSuccessDialogComponent implements AfterViewInit {
  @Input() status: 'in' | 'out' = 'in';

  now: Date = new Date();
  @ViewChild('lottieSiginingSuccess') lottieSiginingSuccess?: ElementRef;
  lastNFCId$ = of(undefined);

  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private store: Store,
    @Inject(APP_CONFIG) public env: AppConfig
  ) {}

  ngAfterViewInit(): void {
    if (this.lottieSiginingSuccess) {
      Lottie.loadAnimation({
        container: this.lottieSiginingSuccess.nativeElement,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path:
          this.status === 'in'
            ? 'app-assets/lotties/clock-in-success.json'
            : 'app-assets/lotties/clock-out-success.json',
      });
    }
  }

  closeAndNavigateToHome() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateRoot('/dashboard');
  }
}
