import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
    selector: 'nai-notification-dot',
    template: ` <span class="font-small">{{ number }}</span> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./notification-dot.component.scss'],
    standalone: true,
})
export class NotificationDotComponent {
  @Input() number?: number;

  @Input() size?: 'small' | 'default';

  @HostBinding('class') get hostClass() {
    return `
      size--${this.size}
    `;
  }
}
