import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { hireServicesReducer } from './+state/edercare.reducer';
import { EdercareEffects } from './+state/edercare.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EDERCARE_REDUCER_KEY } from './+state/edercare.constants';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(EDERCARE_REDUCER_KEY, hireServicesReducer),
    EffectsModule.forFeature([EdercareEffects])
  ],
})
export class EdercareDataAccessModule {}
