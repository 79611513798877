import { Observable } from 'rxjs';
import { ServiceReceiver, User } from './user.models';

export interface NumServicesResponse {
  'number-of-active-services': string;
}
export interface NewServicesResponse {
  'accepted-offers': NewService[];
}
export interface NewService {
  title: string;
  'offer-number': string;
  'offer-name': string;
  'acceptance-date': string;
}
export interface NewServiceDocumentResponse {
  'pdf-link': string;
}

export const ALL_DOCUMENT_CATEGORIES = [
  'budget',
  'invoice',
  'payroll',
  'contract',
  'paech',
  'health-record',
];

export type DocumentCategoryTuple = typeof ALL_DOCUMENT_CATEGORIES;
export type DocumentCategory = DocumentCategoryTuple[number];

export const DocumentCategoryTitles = {
  budget: 'DOCUMENT.DOCUMENTS_PAGE.HEALTH-RECORD',
  invoice: 'DOCUMENT.DOCUMENTS_PAGE.INVOICE',
  payroll: 'DOCUMENT.DOCUMENTS_PAGE.PAYROLL',
  contract: 'DOCUMENT.DOCUMENTS_PAGE.CONTRACT',
  paech: 'DOCUMENT.DOCUMENTS_PAGE.PAECH',
  'health-record': 'DOCUMENT.DOCUMENTS_PAGE.HEALTH-RECORD',
};

export interface BudgetsResponse {
  'accepted-offers': BackBudget[];
}
export interface ContractsResponse {
  contracts: BackContract[];
}
export interface InvoicesResponse {
  invoices: BackInvoice[];
}

export interface PAECHResponse {
  documents: BackPAECH[];
}

export interface PayrollsResponse {
  payrolls: BackPayroll[];
}
export interface HealthRecordsResponse {
  'health-records': BackHealthRecord[];
}

export interface BackBudget extends Omit<Document, 'id'> {
  'offer-number': string;
  'offer-name': string;
  'acceptance-date': string;
  category: 'budget';
}
export interface BackContract extends Omit<Document, 'id'> {
  'contract-eid': string;
  'signed?'?: boolean;
  category: 'contract';
}
export interface BackInvoice extends Omit<Document, 'id'> {
  'invoice-eid': string;
  year: string;
  month: string;
  category: 'invoice';
}
export interface BackPAECH extends Omit<Document, 'id'> {
  eid: string;
  date: string;
  category: 'paech';
}
export interface BackPayroll extends Omit<Document, 'id'> {
  'payroll-eid': string;
  month: string;
  year: string;
  category: 'payroll';
}

export interface BackHealthRecord extends Omit<Document, 'id'> {
  'record-eid': string;
  category: 'health-record';
}

export type Budget = {
  [Property in keyof BackBudget as Property extends 'offer-number'
    ? 'id'
    : Property]: BackBudget[Property];
};
export type Contract = {
  [Property in keyof BackContract as Property extends 'contract-eid'
    ? 'id'
    : Property]: BackContract[Property];
};
export type Invoice = {
  [Property in keyof BackInvoice as Property extends 'invoice-eid'
    ? 'id'
    : Property]: BackInvoice[Property];
};
export type PAECH = {
  [Property in keyof BackPAECH as Property extends 'eid'
    ? 'id'
    : Property]: BackPAECH[Property];
};
export type Payroll = {
  [Property in keyof BackPayroll as Property extends 'payroll-eid'
    ? 'id'
    : Property]: BackPayroll[Property];
};

export type HealthRecord = {
  [Property in keyof BackHealthRecord as Property extends 'record-eid'
    ? 'id'
    : Property]: BackHealthRecord[Property];
};

// export interface Document {
//   id?: string;
//   icon?: 'doc';
//   download: boolean;
//   'signed?'?:boolean | null;
//   type?:string;
//   view: boolean;
// }

export interface Document {
  id: string;
  readonly type: string;
  readonly title: string;
  link?: string;
  icon?: string;
}

export type AnyDocument =
  | Budget
  | Contract
  | Invoice
  | PAECH
  | Payroll
  | HealthRecord;

export type DocumentsByType = {
  [Property in DocumentCategory]: AnyDocument[];
};

export type FetchDocumentFunctions = {
  [Property in DocumentCategory]?: (
    category: DocumentCategory,
    user: User,
    serviceReceiver: ServiceReceiver
  ) => Observable<AnyDocument[]>;
};

export type FetchDocumentFunctionsExecuted = {
  [Property in DocumentCategory]: Observable<AnyDocument[]>;
};
