import { ServiceCategory } from '@nai-libs/shared/data-access/src';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EDERCARE_REDUCER_KEY } from './edercare.constants';
import { EdercareState } from './edercare.reducer';

export const selectEdercareState =
  createFeatureSelector<EdercareState>(EDERCARE_REDUCER_KEY);

export const selectAllServices = createSelector(
  selectEdercareState,
  (state) => state.hireServices
);

export const selectAllServicesPending = createSelector(
  selectEdercareState,
  (state) => state.hireServicesStatus.pending
);

export const selectRecommendedServices = createSelector(
  selectEdercareState,
  (state) => state.recommendedServices
);

export const selectServiceCategory = (id: string) =>
  createSelector(selectEdercareState, (state) => {
    return state.hireServices?.find((hireServices) => hireServices.code == id);
  });

export const selectFilteredServices = createSelector(
  selectEdercareState,
  (state) => {
    const word = state.serviceFilter?.word;
    let filteredCategories = state.hireServices?.filter(
      (category) =>
        state.serviceFilter?.categories?.includes(category.code) ||
        !(state.serviceFilter?.categories ?? []).length
    );
    if (word) {
      filteredCategories = filteredCategories?.reduce((acc, category) => {
        const services = category.services.filter((service) =>
          service.name.toLowerCase().includes(word.toLowerCase())
        );
        return acc.concat({ ...category, services });
      }, [] as Array<ServiceCategory>);
    }

    return filteredCategories;
  }
);

export const selectServiceLocationMarkers = createSelector(
  selectEdercareState,
  (state) => state.serviceLocations
);

export const selectServiceLocationMarkersPending = createSelector(
  selectEdercareState,
  (state) => state.serviceLocationStatus.pending
);

export const selectServiceFilters = createSelector(
  selectEdercareState,
  (state) => {
    return state.serviceFilter;
  }
);

export const selectCart = createSelector(
  selectEdercareState,
  (state) => state.cart
);

export const selectServiceHistoryPending = createSelector(
  selectEdercareState,
  (state) => state.serviceHistoryStatus.pending
);
export const selectServiceHistoryTotal = createSelector(
  selectEdercareState,
  (state) => state.serviceHistory?.nTotal
);
export const selectServiceHistoryItems = createSelector(
  selectEdercareState,
  (state) => state.serviceHistory?.services
);

export const selectService = createSelector(
  selectEdercareState,
  (state) => state.serviceDetail
);
