import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map, Observable, tap } from 'rxjs';

import {
  ConsultationActions,
  ConsultationSelectors,
} from '@nai-libs/consultation/data-access';
import { VideoconsultationAvailability } from '@nai-libs/data-access';

@Injectable({ providedIn: 'root' })
export class SEPAGuard {
  videoconsultationAvailability$: Observable<
    VideoconsultationAvailability | undefined
  > = this.store.select(
    ConsultationSelectors.selectVideoconsultationAvailability
  );

  constructor(private store: Store) {}

  canActivate() {
    return this.videoconsultationAvailability$.pipe(
      tap((availability) => {
        if (!availability) {
          this.store.dispatch(
            ConsultationActions.getVideoconsultationAvailability({})
          );
        }
      }),
      filter((availability) => !!availability),
      map((availability: VideoconsultationAvailability | undefined) => {
        if (!availability?.['SEPA?']) return false;
        return true;
      }),
      first()
    );
  }
}

export const canActivateSEPA: CanActivateFn = () => {
  return inject(SEPAGuard).canActivate();
};
