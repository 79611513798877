import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogSuccessComponent } from '@nai-libs/standalone/dialog-success';
import { NewConsultationSuccessDialogComponent } from '@nai-libs/standalone/new-consultation-success-dialog';
import { NewVideoconsultationSuccessDialogComponent } from '@nai-libs/standalone/new-videoconsultation-success-dialog';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConsultationEffects } from './+state/consultation.effects';
import {
  CONSULTATION_REDUCER_KEY,
  consultationReducer,
} from './+state/consultation.reducer';

@NgModule({
  imports: [
    NewConsultationSuccessDialogComponent,
    NewVideoconsultationSuccessDialogComponent,
    CommonModule,
    DialogSuccessComponent,
    StoreModule.forFeature(CONSULTATION_REDUCER_KEY, consultationReducer),
    EffectsModule.forFeature([ConsultationEffects]),
  ],
})
export class ConsultationDataAccessModule {}
