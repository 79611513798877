import { GoalItem, GoalsByMonth } from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadDailyGoal = createAction('[Goal] Load goal by day', props<{ date: Date }>());
export const loadDailyGoalSuccess = createAction('[Goal] Load goal by day success', props<{ goal: GoalItem }>());
export const loadDailyGoalFailure = createAction('[Goal] Load goal by day failure');

export const modifyGoal = createAction(
  '[Goal] Modify goal',
  props<{ accept: boolean; goal: GoalItem }>()
);

export const modifyGoalSuccess = createAction(
  '[Goal] Modify goal success',
  props<{ goal: GoalItem }>()
);

export const modifyGoalFailure = createAction(
  '[Goal] Modify goal failure',
  props<{ error: string }>()
);

export const loadThreeMonthGoals = createAction(
  '[Goals] Load three month goals'
);

export const loadThreeMonthGoalsSuccess = createAction(
  '[Goals] Load three month goals success',
  props<{ goalsByMonth: GoalsByMonth[] }>()
);

export const loadThreeMonthGoalsFailure = createAction(
  '[Goals] Load three month goals failure'
);
