import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GOAL_REDUCER_KEY, GoalState } from './goal.reducer';

  export const selectGoalState = createFeatureSelector<GoalState>(
    GOAL_REDUCER_KEY
  );

  export const selectGoalPending = createSelector(
    selectGoalState,
    (state) => state.goalStatus.pending
  );
  export const selectGoal = createSelector(
    selectGoalState,
    (state) => state.goal
  );

  export const selectThreeMontGoalsStatusPending = createSelector(
    selectGoalState,
    (state) => state.threeMonthGoalsStatus.pending
  );

