/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import * as ConsultationActions from './consultation.actions';
import * as ConsultationSelectors from './consultation.selectors';
import { ConsultationService } from './consultation.service';

import { Location } from '@angular/common';
import { AlertService } from '@nai-libs/shared/utility/src';
import { NewConsultationSuccessDialogComponent } from '@nai-libs/consultation/features/new-consultation-feature/src/lib/standalone/new-consultation-success-page/new-consultation-success-dialog.component';
import { NewVideoconsultationSuccessDialogComponent } from '@nai-libs/consultation/features/new-consultation-feature/src/lib/standalone/new-videoconsultation-success-dialog/new-videoconsultation-success-dialog.component';
import { NotificationActions } from '@nai-libs/notification/data-access/src';
import { DialogSuccessComponent } from '@nai-libs/standalone/dialog-success/dialog-success.component';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class ConsultationEffects {
  constructor(
    private actions$: Actions,
    private consultationService: ConsultationService,
    private translateService: TranslateService,
    private location: Location,
    private dialog: MatDialog,
    private alert: AlertService,
    private store: Store
  ) {}

  getCustomerSupportProfessionals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.getCustomerSupportProfessionals),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(
                ConsultationSelectors.selectCustomerSupportProfessionals
              )
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([
          ,
          user,
          serviceReceiver,
          selectSelectedUser,
          customerSupportProfessionals,
        ]) => {
          if (!user || !serviceReceiver || !selectSelectedUser)
            return of(UserActions.logout());
          if (customerSupportProfessionals)
            return of(
              ConsultationActions.getCustomerSupportProfessionalsSuccess({
                customerSupportProfessionals,
              })
            );

          return this.consultationService
            .getCustomerSupportProfessionals(
              user,
              serviceReceiver,
              selectSelectedUser
            )
            .pipe(
              map((customerSupportProfessionals) =>
                ConsultationActions.getCustomerSupportProfessionalsSuccess({
                  customerSupportProfessionals,
                })
              ),
              catchError(() =>
                of(ConsultationActions.getCustomerSupportProfessionalsFailure())
              )
            );
        }
      )
    )
  );

  loadFaq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.loadFaq),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ConsultationSelectors.selectFaq)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, user, serviceReciever, selectedUser, faq]) => {
        if (!user || !serviceReciever || !selectedUser)
          return of(UserActions.logout());
        if (faq) return of(ConsultationActions.loadFaqSuccess({ faq }));
        return this.consultationService
          .getFaq(user, serviceReciever, selectedUser)
          .pipe(
            map((faq) => ConsultationActions.loadFaqSuccess({ faq })),
            catchError(() => of(ConsultationActions.loadFaqFailure()))
          );
      })
    )
  );

  loadConsultRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.loadConsultOpeningRules),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ConsultationSelectors.selectConsultRules)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, user, serviceReceiver, selectedUser, consultRules]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());
        if (consultRules)
          return of(
            ConsultationActions.loadConsultOpeningRulesSuccess({ consultRules })
          );
        return this.consultationService
          .getConsultRules(user, serviceReceiver, selectedUser)
          .pipe(
            map((consultRules) =>
              ConsultationActions.loadConsultOpeningRulesSuccess({
                consultRules,
              })
            ),
            catchError(() =>
              of(ConsultationActions.loadConsultOpeningRulesFailure())
            )
          );
      })
    )
  );

  loadConsultHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.loadConsultHistory),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectUser),
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ConsultationSelectors.selectConsultHistory)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, user, serviceReceiver, selectedUser, consultHistory]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());
        // if (consultHistory)
        //   return of(ConsultationActions.loadConsultHistorySuccess({ consultHistory }));
        return this.consultationService
          .getConsultHistory(user, serviceReceiver, selectedUser)
          .pipe(
            map((consultHistory) =>
              ConsultationActions.loadConsultHistorySuccess({ consultHistory })
            ),
            catchError(() =>
              of(ConsultationActions.loadConsultHistoryFailure())
            )
          );
      })
    )
  );

  newConsultation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.newConsultation),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { consultArea, consultSubarea, description, professionalEID },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          return this.consultationService
            .newConsultation(
              consultArea,
              consultSubarea,
              description,
              professionalEID,
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) => ConsultationActions.newConsultationSuccess()),
              catchError(() => of(ConsultationActions.newConsultationFailure()))
            );
        }
      )
    )
  );

  newConsultationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsultationActions.newConsultationSuccess),
        tap(() => {
          this.store.dispatch(ConsultationActions.loadConsultHistory());
          this.dialog.open(NewConsultationSuccessDialogComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-new-consultation-success',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  newResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.newResponse),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { consultEID, consultComment },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          return this.consultationService
            .newResponse(
              consultEID,
              consultComment,
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) =>
                ConsultationActions.newResponseSuccess({ consultEID })
              ),
              catchError(() => of(ConsultationActions.newResponseFailure()))
            );
        }
      )
    )
  );

  newResponseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsultationActions.newResponseSuccess),
        tap(({ consultEID }) => {
          this.store.dispatch(ConsultationActions.loadConsultHistory());
          this.location.historyGo(-1);
          //TODO add icon
          this.alert.showAlert('success', '¡Respuesta enviada!');
        })
      ),
    { dispatch: false }
  );

  newResponseFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsultationActions.newResponseFailure),
        tap((_) => {
          this.alert.showAlert('error', 'El mensaje no se ha podido enviar');
        })
      ),
    { dispatch: false }
  );

  markConsultationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.markConsultationAsRead),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ consultEID }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());
        return this.consultationService
          .markConsultationAsRead(
            consultEID,
            user,
            serviceReceiver,
            selectedUser
          )
          .pipe(
            map((_) => ConsultationActions.markConsultationAsReadSuccess()),
            catchError(() =>
              of(ConsultationActions.markConsultationAsReadFailure())
            )
          );
      })
    )
  );

  markConsultationAsReadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.markConsultationAsReadSuccess),
      map((_) => {
        return NotificationActions.loadNotifications();
      })
    )
  );

  getVideoconsultationAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.getVideoconsultationAvailability),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([{ lastAvailableDate }, user, serviceReceiver, selectedUser]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());

          return this.consultationService
            .getVideoconsultationAvailability(
              user,
              serviceReceiver,
              selectedUser,
              lastAvailableDate
            )
            .pipe(
              map((availability) =>
                ConsultationActions.getVideoconsultationAvailabilitySuccess({
                  availability,
                })
              ),
              catchError(() =>
                of(
                  ConsultationActions.getVideoconsultationAvailabilityFailure()
                )
              )
            );
        }
      )
    )
  );

  newVideoconsultation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.newVideoconsultation),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ intervention }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());
        return this.consultationService
          .newVideoconsultation(
            intervention,
            user,
            serviceReceiver,
            selectedUser
          )
          .pipe(
            map((_) => ConsultationActions.newVideoconsultationSuccess()),
            catchError(() =>
              of(ConsultationActions.newVideoconsultationFailure())
            )
          );
      })
    )
  );

  newVideoconsultationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsultationActions.newVideoconsultationSuccess),
        tap(() => {
          this.store.dispatch(ConsultationActions.loadConsultHistory());
          this.dialog.open(NewVideoconsultationSuccessDialogComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-new-videoconsultation-success',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  sendConsultationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultationActions.sendConsultationEmail),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([{ receiverEmail, receiverName, message }, user, selectedUser]) => {
          if (!user || !selectedUser) return of(UserActions.logout());
          return this.consultationService
            .sendConsultationEmail(
              receiverEmail,
              receiverName,
              message,
              user,
              selectedUser
            )
            .pipe(
              map((_) => ConsultationActions.sendConsultationEmailSuccess()),
              catchError(() =>
                of(ConsultationActions.sendConsultationEmailFailure())
              )
            );
        }
      )
    )
  );

  sendConsultationEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConsultationActions.sendConsultationEmailSuccess),
        tap(() => {
          this.dialog.open(DialogSuccessComponent, {
            data: {
              title: this.translateService.instant(
                'CONSULTATION.CONSULTATIONS_NOT_READY.SUCCESS_DIALOG.TITLE'
              ),
              subtitle: this.translateService.instant(
                'CONSULTATION.CONSULTATIONS_NOT_READY.SUCCESS_DIALOG.SUBTITLE'
              ),
              buttonText: this.translateService.instant(
                'CONSULTATION.CONSULTATIONS_NOT_READY.SUCCESS_DIALOG.BUTTON'
              ),
            },
            maxWidth: 'var(--dialog-max-width)',
            width: 'var(--dialog-width)',
            maxHeight: 'var(--dialog-height)',
            panelClass: ['dialog-panel'],
          });
        })
      ),
    { dispatch: false }
  );
}
