import { DGIResult } from '@nai-libs/shared/data-access/src';
import { createAction, props } from '@ngrx/store';

export const loadMedication = createAction('[Medication] Load medication');

export const loadMedicationSuccess = createAction(
  '[Medication] Load medication success',
  props<{ medication: DGIResult[] }>()
);

export const loadMedicationFailure = createAction(
  '[Medication] Load medication failure',
  props<{ error?: string }>()
);
