import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, DGIResult, ServiceReceiver } from '@nai-libs/data-access';
import { catchError, map, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MedicationService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}
  fetchMedication(serviceReceiver: ServiceReceiver) {
    let params = new HttpParams();

    params = params.append('client-phone', serviceReceiver['client-phone']);

    return this.http
      .get<{ results: DGIResult[] }>(
        `${this.env.apiUrl}health/fetch-medications?${params}`
      )
      .pipe(
        map((res) => {
          // Filtrar sin areas
          // Filtrar sin títulos
          // Filtrar sin slugs
          const DGIResults = res['results'].filter(
            (result) => result.title && result.slug && result.areas.length > 0
          );
          // Filtrar area sin nombre
          // Filtrar area sin problema
          for (let i = 0; i < DGIResults.length; i++) {
            DGIResults[i].areas = DGIResults[i].areas.filter((area) => {
              if (Array.isArray(area.problem)) {
                return area.area && area.problem && area.problem.length > 0;
              } else {
                return area.area && area.problem;
              }
            });

            for (let j = 0; j < DGIResults[i].areas.length; j++) {
              // Filtrar problema array nulo o vacío
              if (Array.isArray(DGIResults[i].areas[j].problem)) {
                DGIResults[i].areas[j].problem = (
                  DGIResults[i].areas[j].problem as string[]
                ).filter((problem) => problem);
              }

              // Filtrar sub cap sin nombre
              // Filtrar sub cap sin problema
              const subcaps = DGIResults[i].areas[j]['sub-caps'];
              if (subcaps && subcaps.length > 0) {
                DGIResults[i].areas[j]['sub-caps'] = subcaps.filter(
                  (subcap) => subcap.area && subcap.problem
                );
              }
            }
          }
          return DGIResults;
        }),
        catchError((err) => throwError(() => err))
      );
  }
}
