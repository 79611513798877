import { Locale } from './translate.models';

export type AppName = string;
export interface AppConfig {
  appName: AppName;
  production: boolean;
  domain: string;
  baseUrl: string;
  apiUrl: string;
  loginUrl?: string;
  availableLanguages: Locale[];
  defaultLanguage: Locale;
  features: Features;
}
interface Features {
  edercare?: boolean;

  wall?: boolean;
  activities?: boolean;
  articles?: boolean;
  clockIn?: boolean;
  taskList?: boolean;
  shortcuts?: boolean;
  goals?: boolean;
  adinberri?:boolean;

  taskForm?: boolean;
  symptoms?: boolean;
  calendar?: boolean;
  CMDForm?: boolean;
  DGI?: boolean;
  healthRecord?: boolean;
  medication?: boolean;
  PAECH?: boolean;

  healthMonitor?: boolean;
  physicalEducation?: boolean;
  danceTherapy?: boolean;
  neuro?: boolean;
  homeAccessibility?: boolean;
  educationalProgram?: boolean;
  nutrition?: boolean;
  zaintzaQuiz?: boolean;
  personalProgress?: boolean;
  editPlan?: boolean;

  videoconsultations?: boolean;
  respiro?: boolean;
  documents?: boolean;
  shop?: boolean;
  buyAndSell?: boolean;
  professionals?: boolean;

  faq?: boolean;
  chats?: boolean;
  contact?: boolean;
  community?: boolean;
  satisfactionSurvey?: boolean;

  members?: boolean;
  statistics?: boolean;
  notifications?: boolean;
  switchUser?: boolean;

  editAvatar?: boolean;
  editProfile?: boolean;
  changePassword?: boolean;
  changeLanguage?: boolean;

  switchServiceReceiver: boolean;

  passwordRecovery: boolean;
}

export type FeatureKey = keyof Features;

export interface Tab {
  name: string;
  path: string;
}

export const REGEX_DNI = /^(([XYZ]{1}[0-9]{7})|([0-9]{8}))[a-zA-Z]{1}$/i; // Includes NIE
export const DNI_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKET';
export const DNI_FIRST_LETTERS = ['X', 'Y', 'Z']; // In NIE
export const REGEX_ZIPCODE = /^\d{5}$/;
