import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClinicalSeverityComponent } from '@nai-libs/standalone/clinical-severity';

@Injectable({
  providedIn: 'root',
})
export class ClinicalSeverityService {
  constructor(private dialog: MatDialog) {}

  showDialog() {
    this.dialog.open(ClinicalSeverityComponent, {
        data: {
          
        },
        panelClass: ['dialog-panel'],
      });
  }
}
