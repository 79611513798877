import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { User } from '@nai-libs/data-access';
import {
    UserSelectors,
    UserService
} from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import {
    Observable,
    catchError,
    combineLatest,
    filter,
    map,
    mergeMap,
    of
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NaihaGuard {
  isUserLoaded$ = this.store.select(UserSelectors.isUserLoaded);
  isServiceReceiverSelected$ = this.store.select(
    UserSelectors.isServiceReceiverSelected
  );
  

  constructor(
    private store: Store,
    private userService: UserService,
    private router: Router
  ) {}

  canLoad():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return combineLatest([this.isUserLoaded$, this.isServiceReceiverSelected$ ]).pipe(
      filter(([isUserLoaded, isServiceReceiverSelected]) => (isUserLoaded && isServiceReceiverSelected)),
      mergeMap(() => this.store.select(UserSelectors.selectUser)),
      map((user: User | undefined) => {
        if (!user?.['logged-user']['naiha-family'])
          return this.router.createUrlTree(['/', 'edercare']);
        return true;
      }),
      catchError((_) => {
        return of(this.router.createUrlTree(['/', 'edercare']));
      })
    );
  }
}

export const canLoadNaiha: CanActivateFn = () => {
  return inject(NaihaGuard).canLoad();
};
