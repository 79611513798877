import { JsonPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { PlanActions } from '@nai-libs/plan/data-access/src';
import { AppConfig, Plan, PlanId } from '@nai-libs/shared/data-access/src';
import { DynamicIconDirective } from '@nai-libs/shared/utility/src/lib/directives/dynicon/dynicon.directive';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CarePlanConfiguratorSectionComponent } from '../components/care-plan-configurator-section.component';
import { APP_CONFIG } from '@nai-libs/app-config';

@Component({
  selector: 'care-plan-configurator',
  template: `
    <section>
      <button [mat-dialog-close] class="button button--icon-close close-icon">
        <span
          class="close-button"
          naiDynIcon
          [path]="'assets/icons/close.svg'"
        ></span>
      </button>
      <header class="header-container">
        <p class="configurator-title">
          {{ 'TAB.PLAN.CONFIGURATOR.TITLE' | translate }}
        </p>
        <p class="configurator-subtitle">
          {{ 'TAB.PLAN.CONFIGURATOR.SUBTITLE' | translate }}
        </p>
      </header>

      <div class="section-container">
        <nai-care-plan-configurator-section
          *ngIf="env.features.educationalProgram"
          [sectionIcon]="'assets/images/t_care.svg'"
          title="{{ 'TAB.PLAN.CONFIGURATOR.CARE_TITLE' | translate }}"
          subtitle="{{ 'TAB.PLAN.CONFIGURATOR.CARE_SUBTITLE' | translate }}"
          [active]="!!planForm.controls['educational-program'].value"
          (toggle)="toggle('educational-program', $event)"
        >
        </nai-care-plan-configurator-section>

        <nai-care-plan-configurator-section
          *ngIf="env.features.physicalEducation"
          [sectionIcon]="'assets/images/t_physical.svg'"
          title="{{ 'TAB.PLAN.CONFIGURATOR.PHYSICAL_TITLE' | translate }}"
          subtitle="{{ 'TAB.PLAN.CONFIGURATOR.PHYSICAL_SUBTITLE' | translate }}"
          [active]="!!planForm.controls['physical-education'].value"
          (toggle)="toggle('physical-education', $event)"
        >
        </nai-care-plan-configurator-section>

        <nai-care-plan-configurator-section
          *ngIf="env.features.neuro"
          [sectionIcon]="'assets/images/t_stimulation.svg'"
          title="{{ 'TAB.PLAN.CONFIGURATOR.COGNITIVE_TITLE' | translate }}"
          subtitle="{{
            'TAB.PLAN.CONFIGURATOR.COGNITIVE_SUBTITLE' | translate
          }}"
          [active]="!!planForm.controls['neuro'].value"
          (toggle)="toggle('neuro', $event)"
        >
        </nai-care-plan-configurator-section>

        <nai-care-plan-configurator-section
          *ngIf="env.features.nutrition"
          [sectionIcon]="'assets/images/t_nutrition.svg'"
          title="{{ 'TAB.PLAN.CONFIGURATOR.NUTRITION_TITLE' | translate }}"
          subtitle="{{
            'TAB.PLAN.CONFIGURATOR.NUTRITION_SUBTITLE' | translate
          }}"
          [active]="!!planForm.controls['nutrition'].value"
          (toggle)="toggle('nutrition', $event)"
        >
        </nai-care-plan-configurator-section>

        <nai-care-plan-configurator-section
          *ngIf="env.features.homeAccessibility"
          [sectionIcon]="'assets/images/t_home.svg'"
          title="{{ 'TAB.PLAN.CONFIGURATOR.HOME_TITLE' | translate }}"
          subtitle="{{ 'TAB.PLAN.CONFIGURATOR.HOME_SUBTITLE' | translate }}"
          [active]="!!planForm.controls['home-adaptation'].value"
          (toggle)="toggle('home-adaptation', $event)"
        >
        </nai-care-plan-configurator-section>

      </div>
      <div class="configurator-footer">
        <button
          type="submit"
          class="button button--primary"
          (click)="handleSubmit()"
        >
          {{ 'TAB.PLAN.CONFIGURATOR.SAVE' | translate }}
        </button>
      </div>
    </section>
  `,
  standalone: true,

  imports: [
    TranslateModule,
    CarePlanConfiguratorSectionComponent,
    DynamicIconDirective,
    MatDialogModule,
    NgIf
  ],
  styleUrls: ['./care-plan-configurator.component.scss'],
})
export class CarePlanConfiguratorComponent implements OnInit {
  planForm = new FormGroup({
    'educational-program': new FormControl(false),
    'physical-education': new FormControl(false),
    neuro: new FormControl(false),
    nutrition: new FormControl(false),
    'home-adaptation': new FormControl(false),
  });

  ngOnInit() {
    if (this.data.plans) {
      const plans = this.data.plans;
      this.planForm.patchValue({
        'educational-program': plans.find(
          (plan) => plan.id === 'educational-program'
        )?.['active?'],
        'physical-education': plans.find(
          (plan) => plan.id === 'physical-education'
        )?.['active?'],
        neuro: plans.find((plan) => plan.id === 'neuro')?.['active?'],
        nutrition: plans.find((plan) => plan.id === 'nutrition')?.['active?'],
        'home-adaptation': plans.find(
          (plan) => plan.id === 'home-adaptation'
        )?.['active?'],
      });
    }
  }

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      plans: Plan[];
    },
    private dialogRef: MatDialogRef<CarePlanConfiguratorComponent>,
    @Inject(APP_CONFIG) public env: AppConfig
  ) {}

  toggle(id: PlanId, value: boolean) {
    this.planForm.controls[id].patchValue(value);
  }

  handleSubmit() {
    const plans: Plan[] = Object.entries(this.planForm.value).map(
      (keyvalue) => ({
        id: keyvalue[0],
        'active?': !!keyvalue[1],
      })
    );

    this.store.dispatch(
      PlanActions.setPlanPreferences({
        plans,
      })
    );
    this.dialogRef.close();
  }
}
