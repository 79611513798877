import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavController } from '@ionic/angular';
import { DialogComponent } from '@nai-libs/edercare/features/edercare-feature/src/lib/components/dialog.component';
import { Service } from '@nai-libs/shared/data-access/src';
import { StorageService } from '@nai-libs/shared/utility/src';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import * as EdercareActions from './edercare.actions';

import { ServiceCartSuccessDialogComponent } from '@nai-libs/standalone/edercare-service-cart-success';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access/src';
import { SERVICE_STORAGE_PREFIX } from './edercare.constants';
import { EdercareService } from './edercare.service';
import { AlertService } from '@nai-libs/shared/utility/src';
import { TOKEN_KEY } from '@nai-libs/user/data-access/src';
@Injectable()
export class EdercareEffects {
  constructor(
    private actions$: Actions,
    private edercareService: EdercareService,
    private store: Store,
    private storage: StorageService,
    private navCtrl: NavController,
    private dialog: MatDialog,
    private alert: AlertService
  ) {}

  loadAvailableServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.loadAvailableServices),
      concatMap(() => {
        return this.edercareService.fetchAvailableServices().pipe(
          map((hireServices) => {
            return EdercareActions.loadAvailableServicesSuccess({
              hireServices,
            });
          }),
          catchError(() => of(EdercareActions.loadAvailableServicesFailure()))
        );
      })
    )
  );

  loadRecommendedServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.loadRecommendedServices),
      withLatestFrom(this.store.select(UserSelectors.selectServiceReceiver)),
      concatMap(([, serviceReceiver]) => {
        return this.edercareService
          .fetchRecommendedServices(serviceReceiver)
          .pipe(
            map((services) => {
              return EdercareActions.loadRecommendedServicesSuccess({
                services,
              });
            }),
            catchError(() =>
              of(EdercareActions.loadRecommendedServicesFailure())
            )
          );
      })
    )
  );

  loadServiceLocationMarkers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.loadServiceLocationMarkers),
      concatMap(({ ZIPCode, service }) => {
        return this.edercareService
          .fetchServiceLocationMarkers(ZIPCode, service)
          .pipe(
            map((response) => {
              return EdercareActions.loadServiceLocationMarkersSuccess({
                markers: response.markers,
              });
            }),
            catchError(() =>
              of(EdercareActions.loadServiceLocationMarkersFailure())
            )
          );
      })
    )
  );

  loadServiceLocationMarkersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EdercareActions.loadServiceLocationMarkersFailure),
        tap((_) => {
          this.alert.showAlert(
            'error',
            'No se ha podido consultar la disponibilidad de los servicios.',
            -1
          );
        })
      ),
    { dispatch: false }
  );

  saveNewServiceModality$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.saveNewServiceModality),
      concatMap(({ modality, info }) => {
        const ref = `${Date.now()}`;
        this.storage.setItem(SERVICE_STORAGE_PREFIX + ref, {
          ref,
          info,
          modality,
        });
        return of(
          EdercareActions.saveNewServiceModalitySuccess({ ref, modality })
        );
      })
    )
  );

  saveNewServiceModalitySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EdercareActions.saveNewServiceModalitySuccess),
        tap(({ ref, modality }) => {
          switch (modality) {
            case 'online':
              this.navCtrl.navigateForward(
                this.edercareService.getRoute('service/' + ref + '/schedule')
              );
              break;
            case 'center-on-site':
            case 'home-on-site':
              this.navCtrl.navigateForward(
                this.edercareService.getRoute('service/' + ref + '/location')
              );
          }
        })
      ),
    { dispatch: false }
  );

  saveNewServiceLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.saveNewServiceLocation),
      mergeMap(({ ref, location, selectedZIP }) => {
        return this.storage.getItem<Service>(SERVICE_STORAGE_PREFIX + ref).pipe(
          map((stored) => {
            if (!stored) return EdercareActions.saveNewServiceLocationFailure();
            this.storage.setItem(SERVICE_STORAGE_PREFIX + ref, {
              ...stored,
              location,
              selectedZIP,
            });
            return EdercareActions.saveNewServiceLocationSuccess({ ref });
          })
        );
      })
    )
  );
  saveNewServiceLocationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EdercareActions.saveNewServiceLocationSuccess),
        tap(({ ref }) => {
          this.navCtrl.navigateForward(this.edercareService.getRoute('service/' + ref + '/schedule'));
        })
      ),
    { dispatch: false }
  );

  saveNewServiceSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.saveNewServiceSchedule),
      mergeMap(({ ref, schedule }) => {
        return this.storage.getItem<Service>(SERVICE_STORAGE_PREFIX + ref).pipe(
          map((stored) => {
            if (!stored) return EdercareActions.saveNewServiceScheduleFailure();
            this.storage.setItem(SERVICE_STORAGE_PREFIX + ref, {
              ...stored,
              schedule,
            });
            return EdercareActions.saveNewServiceScheduleSuccess({ ref });
          })
        );
      })
    )
  );

  // saveNewServiceScheduleSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(EdercareActions.saveNewServiceScheduleSuccess),
  //       tap(({ ref }) => {
  //         this.navCtrl.navigateForward([
  //           '/',
  //           'edercare',
  //           'cart'

  //         ]);
  //       })
  //     ),
  //   { dispatch: false }
  // );

  saveNewServiceScheduleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EdercareActions.saveNewServiceScheduleSuccess),
        tap(({ ref }) => {
          this.navCtrl.navigateForward(
            this.edercareService.getRoute('service/cart')
          );
          const dialogRef = this.dialog.open(DialogComponent, {
            data: {
              title: '¡Genial!',
              description: 'El servicio se ha añadido a la cesta.',
              actions: [
                {
                  id: 'accept',
                  text: 'Continuar',
                  style: 'fill',
                },
                {
                  id: 'continue-shopping',
                  text: 'Seguir añadiendo',
                  style: 'outline',
                },
              ],
            },
            maxWidth: 'var(--dialog-max-width)',
            width: 'var(--dialog-width)',
            maxHeight: 'var(--dialog-height)',
            panelClass: ['dialog-panel', 'dialog-edercare'],
          });

          dialogRef.afterClosed().subscribe((action) => {
            switch (action) {
              case 'continue-shopping':
                this.navCtrl.navigateForward(
                  this.edercareService.getRoute('service')
                );
                break;
            }
          });
        })
      ),
    { dispatch: false }
  );

  loadCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.loadCart),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      concatMap(([, user]) => {
        // if (!user)
        return this.storage.getItems<Service>(SERVICE_STORAGE_PREFIX).pipe(
          map((storedItems) => {
            const cart = storedItems.filter(
              (item) => item !== null && item.schedule
            ) as Service[];
            return EdercareActions.loadCartSuccess({
              cart,
            });
          }),
          catchError(() => of(EdercareActions.loadCartFailure()))
        );

        // return this.edercareService.fetchCart().pipe(
        //   map((cart) => {
        //     return EdercareActions.loadCartSuccess({
        //       cart,
        //     });
        //   }),
        //   catchError(() => of(EdercareActions.loadCartFailure()))
        // );
      })
    )
  );

  removeServiceFromCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.removeServiceFromCart),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([{ ref }, user]) => {
        // if (!user)
        return this.storage.removeItem(SERVICE_STORAGE_PREFIX + ref).pipe(
          map((_) => EdercareActions.removeServiceFromCartSuccess({ ref })),
          catchError(() => of(EdercareActions.removeServiceFromCartFailure()))
        );
        // return this.edercareService.removeServiceFromCart(ref).pipe(
        //   map((ref) => {
        //     return EdercareActions.removeServiceFromCartSuccess({ ref });
        //   }),
        //   catchError(() => of(EdercareActions.removeServiceFromCartFailure()))
        // );
      })
    )
  );

  removeServiceFromCartSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.removeServiceFromCartSuccess),
      map((_) => EdercareActions.loadCart())
    )
  );

  saveCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.saveCart),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([{ cart }, user]) => {
        if (!user) return of(UserActions.logout());
        return this.edercareService.saveCart(cart).pipe(
          map((cart) => {
            return EdercareActions.saveCartSuccess({ cart });
          }),
          catchError(() => of(EdercareActions.saveCartFailure()))
        );
      })
    )
  );

  saveCartSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.saveCartSuccess),
      map(({ cart }) => {
        this.dialog.open(ServiceCartSuccessDialogComponent, {
          data: {
            cart,
          },
          panelClass: ['dialog-full'],
        });
        return EdercareActions.emptyCart();
      })
    )
  );

  emptyCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.emptyCart),
      switchMap(() => {
        return this.storage
          .removeItems(SERVICE_STORAGE_PREFIX)
          .pipe(map(() => EdercareActions.emptyCartSuccess()));
      })
    )
  );

  loadServiceHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.loadServiceHistory),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([{ filters, page, pageSize }, user, serviceReceiver]) => {
        // TODO service receiver is necessary
        if (!user) return of(EdercareActions.loadServiceHistoryFailure());
        return this.edercareService
          .loadServiceHistory(filters, page, pageSize, serviceReceiver)
          .pipe(
            map((response) => {
              return EdercareActions.loadServiceHistorySuccess({
                services: response.services,
                total: response.total,
                page,
                filters,
              });
            }),
            catchError(() => of(EdercareActions.loadServiceHistoryFailure()))
          );
      })
    )
  );

  loadService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.loadService),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([{ ref }, user, serviceReceiver]) => {
        if (!user) return of(EdercareActions.loadServiceFailure());
        return this.edercareService.loadService(serviceReceiver, ref).pipe(
          map((response) => {
            return EdercareActions.loadServiceSuccess({
              service: response.services[0],
            });
          }),
          catchError(() => of(EdercareActions.loadServiceHistoryFailure()))
        );
      })
    )
  );

  contact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdercareActions.contact),
      switchMap(({ contactInfo }) => {
        return this.edercareService.contact(contactInfo).pipe(
          map((_) => EdercareActions.contactSuccess()),
          catchError(() => of(EdercareActions.contactFailure()))
        );
      })
    )
  );

  contactSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EdercareActions.contactSuccess),
        tap((_) => {
          const dialogRef = this.dialog.open(DialogComponent, {
            data: {
              image: 'assets/icons/ok-check.svg',
              title: '¡Genial!',
              subtitle: 'Nos pondremos en contacto contigo lo antes posible.',
              actions: [
                {
                  id: 'ok',
                  text: 'Continuar',
                  style: 'fill',
                },
              ],
            },
            maxWidth: 'var(--dialog-max-width)',
            width: 'var(--dialog-width)',
            maxHeight: 'var(--dialog-height)',
            panelClass: ['dialog-panel', 'dialog-edercare'],
          });

          dialogRef
            .afterClosed()
            .subscribe((_) => this.navCtrl.navigateRoot(this.edercareService.getRoute('/')));
        })
      ),

    { dispatch: false }
  );
}
