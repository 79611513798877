import {
  NewUser,
  RequestStatus,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const USER_REDUCER_KEY = 'user';

export interface UserState {
  logged: boolean;
  loginStatus: RequestStatus;
  loginRoute?: string;
  user?: User;
  loadUserDataStatus: RequestStatus;
  passwordRecoveryStatus: RequestStatus;
  newPasswordStatus: RequestStatus;
  acceptUsingTermsStatus: RequestStatus;
  selectedServiceReceiver?: ServiceReceiver;
  selectedUser?: SelectedUser;
  changeLanguageStatus: RequestStatus;
  changeAvatarColorStatus: RequestStatus;
  editUserStatus: RequestStatus;
  signUpData?: NewUser;
  terms?: string;
  policy?: string;
  termsAndPolicyStatus: RequestStatus;
}

const initialState: UserState = {
  logged: false,
  loginStatus: {
    pending: false,
  },
  loadUserDataStatus: {
    pending: false,
  },
  passwordRecoveryStatus: {
    pending: false,
  },
  newPasswordStatus: {
    pending: false,
  },
  acceptUsingTermsStatus: {
    pending: false,
  },
  changeLanguageStatus: {
    pending: false,
  },
  changeAvatarColorStatus: {
    pending: false,
  },
  editUserStatus: {
    pending: false,
  },
  termsAndPolicyStatus: {
    pending: false
  }
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.login, (state) => ({
    ...state,
    loginStatus: { pending: true },
  })),
  on(UserActions.logout, _ => initialState),
  on(UserActions.loginSuccess, (state) => ({
    ...state,
    loginStatus: { pending: false },
  })),
  on(UserActions.loginFailure, (state) => ({
    ...state,
    loginStatus: { pending: false },
  })),

  on(UserActions.loadUserData, (state) => ({
    ...state,
    loadUserDataStatus: { pending: true },
  })),
  on(UserActions.loadUserDataSuccess, (state, { user }) => {
    return {
      ...state,
      user,
      loadUserDataStatus: { pending: false },
    };
  }),
  on(UserActions.loadUserDataFailure, (state) => ({
    ...state,
    user: undefined,
    loadUserDataStatus: { pending: false },
  })),

  on(UserActions.setUser, (state, { user }) => {
    return { ...state, user };
  }),
  on(UserActions.setServiceReceiverSuccess, (state, { serviceReceiver }) => ({
    ...state,
    selectedServiceReceiver: serviceReceiver,
  })),

  on(UserActions.setSelectedUser, (state, { selectedUser }) => ({
    ...state,
    selectedUser: selectedUser,
  })),

  on(UserActions.sendPasswordRecoveryEmail, (state) => ({
    ...state,
    passwordRecoveryStatus: { pending: true },
  })),
  on(UserActions.sendPasswordRecoveryEmailSuccess, (state) => ({
    ...state,
    passwordRecoveryStatus: { pending: false },
  })),
  on(UserActions.sendPasswordRecoveryEmailFailure, (state, { error }) => ({
    ...state,
    passwordRecoveryStatus: { pending: false, error },
  })),

  on(UserActions.saveNewPassword, (state) => ({
    ...state,
    newPasswordStatus: { pending: true },
  })),
  on(UserActions.saveNewPasswordSuccess, (state) => ({
    ...state,
    newPasswordStatus: { pending: false },
  })),
  on(UserActions.saveNewPasswordFailure, (state, { error }) => ({
    ...state,
    newPasswordStatus: { pending: false, error },
  })),

  on(UserActions.acceptUsingTerms, (state) => ({
    ...state,
    acceptUsingTermsStatus: { pending: true },
  })),
  on(UserActions.acceptUsingTermsSuccess, (state) => ({
    ...state,
    acceptUsingTermsStatus: { pending: false },
  })),
  on(UserActions.acceptUsingTermsFailure, (state, { error }) => ({
    ...state,
    acceptUsingTermsStatus: { pending: false, error },
  })),

  on(UserActions.changeLanguage, (state) => ({
    ...state,
    changeLanguageStatus: { pending: true },
  })),
  on(UserActions.changeLanguageSuccess, (state) => ({
    ...state,
    changeLanguageStatus: { pending: false },
  })),
  on(UserActions.changeLanguageFailure, (state) => ({
    ...state,
    changeLanguageStatus: { pending: false },
  })),
  on(UserActions.changeAvatarColor, (state) => ({
    ...state,
    changeAvatarColorStatus: { pending: true },
  })),
  on(UserActions.changeAvatarColorSuccess, (state) => ({
    ...state,
    changeAvatarColorStatus: { pending: false },
  })),
  on(UserActions.changeAvatarColorFailure, (state) => ({
    ...state,
    changeAvatarColorStatus: { pending: false },
  })),
  on(UserActions.editUser, (state) => ({
    ...state,
    editUserStatus: { pending: true },
  })),
  on(UserActions.editUserSuccess, (state) => ({
    ...state,
    editUserStatus: { pending: false },
  })),
  on(UserActions.editUserFailure, (state) => ({
    ...state,
    editUserStatus: { pending: false },
  })),
  on(UserActions.saveSignUpData, (state, { user }) => ({
    ...state,
    signUpData: user,
  })),
  on(UserActions.signUpSuccess, (state, { clientPhone }) => ({
    ...state,
    signUpData: { ...state.signUpData, clientPhone } as NewUser,
  })),
  on(UserActions.clearSignUpData, (state) => ({
    ...state,
    signUpData: undefined,
  })),
  on(UserActions.setLoginRedirectRoute, (state, { route }) => ({
    ...state,
    loginRoute: route,
  })),
  on(UserActions.clearLoginRedirectRoute, (state) => ({
    ...state,
    loginRoute: undefined,
  })),
  on(UserActions.loadTermsAndPolicy, (state) => ({
    ...state,
    termsAndPolicyStatus: { pending: true },
  })),
  on(UserActions.loadTermsAndPolicySuccess, (state, {policy, terms}) => ({
    ...state,
    terms,
    policy: policy,
    termsAndPolicyStatus: { pending: false },
  })),
  on(UserActions.loadTermsAndPolicyFailure, (state) => ({
    ...state,
    termsAndPolicyStatus: { pending: false },
  })),
);
