import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PasswordChangeSuccessPageComponent } from './password-change-success-page.component';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [CommonModule, RouterModule, DynamicIconDirective, MatDialogModule, PasswordChangeSuccessPageComponent]
})
export class PasswordChangeSuccessPageModule {}
