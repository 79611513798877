import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';

@Component({
    selector: 'nai-password-reset-success',
    template: `
    <div class="page">
      <div class="wrapper-fluid">
        <span
          naiDynIcon
          [overrideColor]="false"
          [path]="'assets/icons/ok-check.svg'"
        ></span>
        <h1>¡Genial!</h1>
        <p>Contraseña cambiada correctamente.</p>
        <div class="actions-wrapper">
          <a
            [routerLink]="['login']"
            class="button button--outline button--primary"
            [mat-dialog-close]
            >Volver a iniciar sesión</a
          >
        </div>
      </div>
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./password-reset-success-page.component.scss'],
    standalone: true,
    imports: [
        DynamicIconDirective,
        RouterLink,
        MatDialogModule,
    ],
})
export class PasswordResetSuccessPageComponent {}
