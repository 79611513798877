export interface AtomItem {
  type: 'button' | 'card'
  icon: string;
  name: string;
  title: string;
  description?: string;
  cta?: string;
  externalUrl?: string;
  path: string;
}

