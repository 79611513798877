import {
  DGIResult,
  RequestStatus,
} from '@nai-libs/data-access';
import { createReducer, on } from '@ngrx/store';

import * as MedicationActions from './medication.actions';

export const MEDICATION_REDUCER_KEY = 'medication';

export interface MedicationState {
  medication?: DGIResult[];
  medicationStatus: RequestStatus;
}

const initalState: MedicationState = {
  medicationStatus: { pending: false },
};

export const medicationReducer = createReducer(
  initalState,
  on(MedicationActions.loadMedication, (state) => ({
    ...state,
    medicationStatus: { pending: true },
  })),
  on(MedicationActions.loadMedicationSuccess, (state, {medication}) => ({
    ...state,
    medication,
    medicationStatus: { pending: false },
  })),
  on(MedicationActions.loadMedicationFailure, (state) => ({
    ...state,
    medicationStatus: { pending: false },
  }))
);
