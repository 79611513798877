import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  NoticeBoardArticle,
  NoticeBoardArticlePreview,
  NoticeBoardResponse,
  ServiceReceiver,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';

import { DateUtilsService } from '@nai-libs/utility';

@Injectable({
  providedIn: 'root',
})
export class NoticeBoardService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private dateUtils: DateUtilsService
  ) {}

  /**
   * Método para obtener los artículos de interés de un cliente
   * @return Observable<NoticeBoardArticlePreview[]>
   */
  fetchNoticeBoardArticlePreviews(
    serviceReceiver?: ServiceReceiver
  ): Observable<NoticeBoardArticlePreview[]> {
    let params = new HttpParams();

    params = params.append(
      'date',
      this.dateUtils.dateToYearMonthDayHyphen(new Date())
    );
    if (serviceReceiver)
      params = params.append('client-phone', serviceReceiver['client-phone']);

    return this.http
      .get<NoticeBoardResponse>(
        `${this.env.apiUrl}home/notifications/information-wall?${params}`
      )
      .pipe(
        map(
          (response) => {
            return response['result'].length > 0 ? response['result'] : [];
          }

          // response['formative-articles'].length > 0
          //   ? response['formative-articles']
          //   : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchNoticeBoardArticle(
    id: string,
    serviceReceiver?: ServiceReceiver
  ): Observable<NoticeBoardArticle> {
    let params = new HttpParams();

    params = params.append('id', id);
    if (serviceReceiver)
      params = params.append('client-phone', serviceReceiver['client-phone']);

    return this.http
      .get<NoticeBoardArticle>(
        `${this.env.apiUrl}home/information-wall-article?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }
}
