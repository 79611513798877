import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as NeuroActions from './neuro.actions';
import { NeuroService } from './neuro.service';

import { UserActions, UserSelectors } from '@nai-libs/user/data-access';

@Injectable()
export class NeuroEffects {
  constructor(
    private actions$: Actions,
    private neuro: NeuroService,
    private store: Store
  ) {}

  neuroLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NeuroActions.neuroLogin),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser) return of(UserActions.logout);
        return this.neuro.login(user, serviceReceiver, selectedUser).pipe(
          map((neuroLink) => NeuroActions.neuroLoginSuccess({ neuroLink })),
          catchError((_) => of(NeuroActions.neuroLoginFailure()))
        );
      })
    )
  );

  neuroLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NeuroActions.neuroLoginSuccess),
        tap(({ neuroLink }) => {
          window.open(`${neuroLink}`, '_self');
        })
      ),
    { dispatch: false }
  );
}
