import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  LoggedUser,
  SelectedUser,
  SelectedUserRole,
  ServiceReceiver,
} from '@nai-libs/data-access';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import { NotificationSelectors } from 'libs/notification/data-access/src';
import { map } from 'rxjs';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule, MenuController } from '@ionic/angular';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';
import { EdercareService } from '@nai-libs/edercare/data-access';
import { AvatarComponent } from '@nai-libs/standalone/avatar';
import { ButtonAvatarComponent } from '@nai-libs/standalone/button-avatar';
import { MenuListComponent } from '@nai-libs/standalone/menu-list';
import { NotificationDotComponent } from '@nai-libs/standalone/notification-dot';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'nai-accessible-menu',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    AvatarComponent,
    RouterModule,
    DynamicIconDirective,
    MenuListComponent,
    TranslateModule,
    ButtonAvatarComponent,
    NotificationDotComponent,
  ],
  template: `
    <ion-menu
      menuId="main-menu"
      contentId="main-content"
      class="menu"
      side="end"
      swipeGesture="false"
      *ngIf="{
        serviceReceiver: serviceReceiver$ | async,
        user: user$ | async,
        selectedUser: selectedUser$ | async,
        userRole: userRole$ | async
      } as vm"
    >
      <header>
        <figure>
          <img [src]="'app-assets/images/trophy_white.svg'" />
        </figure>
        <button
          class="button button--icon-close close-button"
          (click)="closeMenu()"
        >
          <span
            class="close"
            naiDynIcon
            [path]="'assets/icons/close.svg'"
          ></span>
        </button>
      </header>
      <ion-content>
        <div class="content">
          <!-- Administrar perfil -->
          <section class="menu-buttons menu-container">
            <div class="wrapper-fluid buttons-container">
              <div class="accessible-button">
                <a
                  class="menu-button"
                  [routerLink]="
                    env.features.edercare ? ['/edercare/profile'] : ['/perfil']
                  "
                  (click)="closeMenu()"
                >
                  <div class="accessible-button-container">
                    <span
                      class="edit-icon"
                      naiDynIcon
                      [path]="'assets/icons/person-outline.svg'"
                    ></span>
                    <span class="menu-button-label">
                      {{ 'USER.PROFILE.MENU.MY_ACCOUNT' | translate }}
                    </span>
                  </div>
                </a>
              </div>

              <!-- <ng-container
                  *ngIf="
                    env.features.members &&
                    (userRole$ | async) === 'Familiar referente'
                  "
                  id="main-menu"
                >
                  <a class="menu-button" [routerLink]="['/', 'members']">
                    <span
                      class="edit-icon"
                      naiDynIcon
                      [path]="'assets/icons/face.svg'"
                    ></span>

                    <span class="font-subhead-1 menu-button-label">
                      {{ 'USER.PROFILE.MENU.MEMBERS' | translate }}
                    </span>
                  </a>
                </ng-container> -->

              <ng-container
                *ngIf="!env.features.edercare || (canAccessNaiha$ | async)"
              >
                <div class="accessible-button">
                  <a
                    class="menu-button"
                    [routerLink]="['/', 'members']"
                    (click)="closeMenu()"
                  >
                    <div class="accessible-button-container">
                      <span
                        class="edit-icon"
                        naiDynIcon
                        [path]="'assets/icons/face.svg'"
                      ></span>
                      <span class="menu-button-label">
                        {{ 'USER.PROFILE.MENU.OTHER_ACCOUNTS' | translate }}
                      </span>
                    </div>
                  </a>
                </div>
              </ng-container>

              <ng-container
                *ngIf="!env.features.edercare || (canAccessNaiha$ | async)"
              >
                <div class="accessible-button">
                  <a
                    class="menu-button"
                    [routerLink]="['/notificaciones']"
                    (click)="closeMenu()"
                  >
                    <div class="accessible-button-container">
                      <span
                        class="edit-icon"
                        naiDynIcon
                        [path]="'assets/icons/notifications.svg'"
                      ></span>
                      <nai-notification-dot
                        *ngIf="notifications$ | async as notifications"
                        [number]="notifications"
                      ></nai-notification-dot>
                      <span class="menu-button-label">
                        {{ 'USER.PROFILE.MENU.ALERTS' | translate }}
                      </span>
                    </div>
                  </a>
                </div>
              </ng-container>

              <!--  <ng-container
                *ngIf="!env.features.edercare || (canAccessNaiha$ | async)"
              >
                <div class="accessible-button">
                  <a
                    class="menu-button"
                    [routerLink]="['/estadisticas']"
                    (click)="closeMenu()"
                  >
                    <div class="accessible-button-container">
                      <span
                        class="edit-icon"
                        naiDynIcon
                        [path]="'assets/icons/stats.svg'"
                      ></span>
                      <span class="menu-button-label">
                        {{ 'USER.PROFILE.MENU.STATS' | translate }}
                      </span>
                    </div>
                  </a>
                </div>
              </ng-container> -->

              <a
                *ngIf="env.features.edercare"
                class="menu-button"
                [routerLink]="edercareService.getRoute('service/history')"
                [routerDirection]="'forward'"
                (click)="closeMenu()"
              >
                <span naiDynIcon [path]="'assets/icons/care.svg'"></span>
                <span class="font-subhead-1 menu-button-label"
                  >Mis servicios</span
                >
              </a>
            </div>
          </section>

          <div *ngIf="env.features.edercare" class="menu__bottom">
            <button
              class="button button--edercare button--link"
              (click)="logout()"
            >
              <span>Cerrar sesión</span>
            </button>
          </div>

          <!-- Cambiar de perfil -->
          <section
            *ngIf="env.features.switchUser && vm.userRole !== 'Paciente'"
            class="profile-change wrapper-fluid"
          >
            <div>
              <h1 class="font-overline">
                {{ 'USER.PROFILE.MENU.SWITCH_USER' | translate }}
              </h1>

              <div class="users-container">
                <ion-menu-toggle
                  id="main-menu"
                  *ngIf="
                    vm.serviceReceiver &&
                    vm.selectedUser?.id !== vm.serviceReceiver?.id
                  "
                >
                  <nai-button-avatar
                    [color]="vm.serviceReceiver.avatar || '#ffb948'"
                    [text]="vm.serviceReceiver.identifier.name || ''"
                    [initial]="
                      vm.serviceReceiver.identifier.name.charAt(0) || ''
                    "
                    [image]="vm.serviceReceiver.image"
                    (click)="changeSelectedUser(vm.serviceReceiver)"
                    [type]="'list-item'"
                    size="large"
                  >
                  </nai-button-avatar>
                </ion-menu-toggle>
                <ion-menu-toggle
                  id="main-menu"
                  *ngIf="vm.user?.['logged-user'] && (vm.selectedUser?.id !== vm.user?.['logged-user']?.id)"
                >
                  <nai-button-avatar
                    [color]="vm.user?.['logged-user']?.avatar || '#ffb948'"
                    [text]="vm.user?.['logged-user']?.identifier?.name || ''"
                    [initial]="vm.user?.['logged-user']?.identifier?.name?.charAt(0) || ''"
                    (click)="changeSelectedUser(vm.user?.['logged-user'])"
                    [type]="'list-item'"
                    [image]="vm.user?.['logged-user']?.image"
                    size="large"
                  >
                  </nai-button-avatar>
                </ion-menu-toggle>
              </div>
            </div>
            <!--TODO:check if we can have multiple service receivers-->

            <!-- <ion-menu-toggle class="button-close-menu">
              <button class="button button--clear">Cerrar menú</button>
            </ion-menu-toggle> -->

            <!-- {{ vm.selectedUser | json }}
          {{ vm.selectedUser?.['user-id'] | json }} -->
          </section>
        </div>
      </ion-content>
    </ion-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./accessible-menu.component.scss'],
})
export class AccessibleMenuComponent {
  user$ = this.store.select(UserSelectors.selectUser);
  serviceReceiver$ = this.store.select(UserSelectors.selectServiceReceiver);
  selectedUser$ = this.store.select(UserSelectors.selectSelectedUser);
  userRole$ = this.store.select(UserSelectors.selectLoggedUserRole);
  canAccessNaiha$ = this.store.select(UserSelectors.canAccessNaiha);
  notifications$ = this.store
    .select(NotificationSelectors.selectNotifications)
    .pipe(map((notifications) => notifications?.notifications ?? 0));
  constructor(
    private store: Store,
    @Inject(APP_CONFIG) public env: AppConfig,
    public edercareService: EdercareService,
    private menuCtrl: MenuController
  ) {}

  changeSelectedUser(user: LoggedUser | ServiceReceiver | undefined) {
    if (user) {
      let userId: string;
      let selectedUserRole: SelectedUserRole;

      if ('client-phone' in user) {
        userId = user['client-phone'];
      } else if ('id' in user) {
        userId = user['e-mail'];
      } else {
        return;
      }
      if ('naiha-fam-access' in user) {
        selectedUserRole = user['naiha-fam-access'];
      } else {
        selectedUserRole = 'Service Receiver';
      }
      const selectedUser: SelectedUser = {
        'user-id': userId,
        avatar: user.avatar,
        'user-role': selectedUserRole,
        identifier: user.identifier,
        id: user.id,
        image: user.image,
      };
      console.log(selectedUser)
      this.store.dispatch(
        UserActions.changeSelectedUser({
          selectedUser,
        })
      );
    }
  }

  closeMenu() {
    this.menuCtrl.close('main-menu');
  }
  logout() {
    this.closeMenu();
    this.store.dispatch(UserActions.logout());
  }
}
