import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Notifications } from '@nai-libs/shared/data-access/src';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { NotificationActions, NotificationSelectors } from '../..';
import { NotificationService } from './notification.service';
@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private store: Store
  ) {}

  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.loadNotifications),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, user, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !user || !selectedUser) {
          return of(NotificationActions.loadNotificationsFailure());
        }

        return this.notificationService
          .fetchNotifications(user, serviceReceiver, selectedUser)
          .pipe(
            map((notifications: Notifications) =>
              NotificationActions.loadNotificationsSuccess({ notifications })
            ),
            catchError(() => of(NotificationActions.loadNotificationsFailure()))
          );
      })
    )
  );

  saveReadNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.saveReadNotification),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id }, user, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !user || !selectedUser) {
          return of(UserActions.logout());
        }

        return this.notificationService
          .saveReadNotification(id, user, serviceReceiver, selectedUser)
          .pipe(
            map((_) => NotificationActions.saveReadNotificationSuccess()),
            catchError(() =>
              of(NotificationActions.saveReadNotificationFailure())
            )
          );
      })
    )
  );

  // IMPROVE No multiple actions in effects
  saveReadNotificationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.saveReadNotificationSuccess),
      switchMap((_) => {
        return [
          NotificationActions.loadNotifications(),
          NotificationActions.loadNotificationHistory(),
        ];
      })
    )
  );

  loadNotificationHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.loadNotificationHistory),
      withLatestFrom(
        this.store.select(
          NotificationSelectors.selectNotificationPageDateFilter
        ),
        this.store.select(
          NotificationSelectors.selectNotificationPageTypeFilter
        ),
        this.store.select(
          NotificationSelectors.selectNotificationPagePagination
        ),
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, date, type, page, user, serviceReceiver, selectedUser]) => {
        if (!user) {
          return of(NotificationActions.loadNotificationHistoryFailure());
        }

        return this.notificationService
          .fetchNotificationHistory(
            user,
            serviceReceiver,
            selectedUser,
            date,
            type,
            page
          )
          .pipe(
            map((notifications) =>
              NotificationActions.loadNotificationHistorySuccess({
                notifications,
              })
            ),
            catchError(() =>
              of(NotificationActions.loadNotificationHistoryFailure())
            )
          );
      })
    )
  );

  setNotificationsPageTypeFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.setNotificationsPageTypeFilter),
      map((_) => NotificationActions.setNotificationsPageTypeFilterSuccess())
    )
  );

  setNotificationsPageDateFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.setNotificationsPageDateFilter),
      map((_) => NotificationActions.setNotificationsPageDateFilterSuccess())
    )
  );

  setNotificationsPagePagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.setNotificationsPagePagination),
      map((_) => NotificationActions.setNotificationsPagePaginationSuccess())
    )
  );

  setNotificationsPageTypeFilterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.setNotificationsPageTypeFilterSuccess),
      map((_) => NotificationActions.loadNotificationHistory())
    )
  );

  setNotificationsPageDateFilterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.setNotificationsPageDateFilterSuccess),
      map((_) => NotificationActions.loadNotificationHistory())
    )
  );

  setNotificationsPagePaginationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.setNotificationsPagePaginationSuccess),
      map((_) => NotificationActions.loadNotificationHistory())
    )
  );

  modifyNotificationAttendance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.modifyNotificationAttendance),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([{ accept, notification }, user, serviceReceiver]) => {
        if (!serviceReceiver || !user) return of(UserActions.logout());
        return this.notificationService
          .modifyNotificationAttendance(
            accept,
            notification,
            user,
            serviceReceiver
          )
          .pipe(
            map((response) =>
              response['success?']
                ? NotificationActions.modifyNotificationAttendanceSuccess()
                : NotificationActions.modifyNotificationAttendanceFailure({
                    error: 'The resquest responded unsuccessfully',
                  })
            ),
            catchError((error) =>
              of(
                NotificationActions.modifyNotificationAttendanceFailure({
                  error,
                })
              )
            )
          );
      })
    )
  );

  // IMPROVE No multiple actions in effects
  modifyAppointmentAttendanceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.modifyNotificationAttendanceSuccess),
      switchMap((_) => {
        return [
          NotificationActions.loadNotificationHistory(),
          NotificationActions.loadNotifications(),
        ];
      })
    )
  );
}
