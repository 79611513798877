import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';

@Component({
  selector: 'nai-dialog',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    DynamicIconDirective,
    MatDialogModule,
    RouterLink,
  ],
  template: `
    <header class="dialog__header">
      <button [mat-dialog-close] class="button button--icon-close">
        <span naiDynIcon [path]="'assets/icons/close.svg'"></span>
      </button>
    </header>

    <mat-dialog-content class="dialog__content ">
      <span
        *ngIf="data.icon"
        class="dialog__icon"
        [style.--color]="data.iconColor ?? 'var(--color-primary)'"
        naiDynIcon
        [path]="data.icon"
      ></span>
      <img *ngIf="data.image" class="dialog__image" [src]="data.image" />
      <h1 class="font-headline-4 dialog__title">{{ data.title }}</h1>
      <p class="dialog__description">{{ data.description }}</p>

      <div
        *ngIf="data.innerHTML"
        class="dialog__inner-html"
        [innerHTML]="data.innerHTML"
      ></div>
      <div
        class="dialog__actions-wrapper"
        *ngIf="data.actions && data.actions.length"
      >
        <div *ngFor="let action of data.actions; let i = index">
          <label class="dialog__actions-label" *ngIf="action.label">{{ action.label }}</label>

          <button
            class="button button--edercare"
            [ngClass]="{
            'button--fill': action.style === 'fill',
            'button--outline': action.style === 'outline',
            'button--link': action.style === 'link',
          }"
            [mat-dialog-close]="action.id"
          >
            <span *ngIf="action.icon" naiDynIcon [path]="action.icon"></span>
            <span>{{ action.text }}</span>
          </button>
        </div>
      </div>
    </mat-dialog-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      icon?: string;
      iconColor?: string;
      image?: string;
      title?: string;
      description?: string;
      innerHTML?: string;
      actions?: {
        label?: string;
        icon?: string;
        id: string;
        text: string;
        style: 'fill' | 'outline' | 'link';
      }[];
    }
  ) {}
}
