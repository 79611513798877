import {
  Directive,
  ElementRef,
  HostBinding, Input
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[naiDynIcon]',
})
export class DynamicIconDirective {

  @HostBinding('class.dynicon') dyniconClass = true;
  @Input() overrideColor = true;
  @Input() set path(value: string) {
    if (value) {
      this.el.nativeElement.style.setProperty('--width', 'var(--size, 24px)');
    }
    this.el.nativeElement.style.setProperty('--height', 'var(--size, 24px)');
    this.el.nativeElement.style['width'] = 'var(--width, 24px)';
    this.el.nativeElement.style['min-width'] = 'var(--width, 24px)';
    this.el.nativeElement.style['height'] = 'var(--height, 24px)';
    if (this.overrideColor) {
      this.el.nativeElement.style['background-color'] = 'var(--color, #000)';
      this.el.nativeElement.style[
        'mask'
      ] = `url(${value}) no-repeat center / contain`;
      this.el.nativeElement.style[
        '-webkit-mask'
      ] = `url(${value}) no-repeat center / contain`;
    } else {
      this.el.nativeElement.style[
        'background'
      ] = `url(${value}) no-repeat center / contain`;
    }
    
  }

  constructor(private el: ElementRef) {}
}
