import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PlanEffects } from './+state/plan.effects';
import { PLAN_REDUCER_KEY, PlanReducer } from './+state/plan.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PLAN_REDUCER_KEY, PlanReducer),
    EffectsModule.forFeature([PlanEffects]),
  ],
})
export class PlanDataAccessModule {}
