import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GoalEffects } from './+state/goal.effects';
import { GOAL_REDUCER_KEY, goalReducer } from './+state/goal.reducer';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(GOAL_REDUCER_KEY, goalReducer),
    EffectsModule.forFeature([GoalEffects]),
  ],
})
export class GoalDataAccessModule {}


