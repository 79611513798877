import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ClockInEffects } from './+state/clock-in.effects';
import { StoreModule } from '@ngrx/store';
import { clockInReducer, CLOCK_IN_FEATURE_KEY } from './+state/clock-in.reducer';
import { ClockInSuccessDialogComponent } from '@nai-libs/standalone/clock-in-success-dialog';

@NgModule({
  imports: [
    CommonModule,
    ClockInSuccessDialogComponent,
    StoreModule.forFeature(CLOCK_IN_FEATURE_KEY, clockInReducer),
    EffectsModule.forFeature([ClockInEffects])
  ]
})
export class ClockInDataAccessModule {}
