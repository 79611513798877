import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlanState, PLAN_REDUCER_KEY } from './plan.reducer';

export const selectPlanState =
  createFeatureSelector<PlanState>(PLAN_REDUCER_KEY);

export const selectPlans = createSelector(
  selectPlanState,
  (state) => state.plans?.['health-plan']
);

export const selectPlansConfig = createSelector(
  selectPlanState,
  (state) => state.plans
);

export const selectHealthPlanProgress = createSelector(
  selectPlanState,
  (state) => state.healthPlanProgress
);

export const selectHealthPlanSummary = createSelector(
  selectPlanState,
  (state) => state.healthPlanSummary
);

export const selectPlansPending = createSelector(
  selectPlanState,
  (state) => state.plansStatus.pending
);

export const selectsetPlanPreferencesPending = createSelector(
  selectPlanState,
  (state) => state.setPlanPreferencesStatus.pending
);
