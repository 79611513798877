import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { AlertService } from '@nai-libs/shared/utility/src';
import {
  ConsultationActions,
  ConsultationSelectors,
} from 'libs/consultation/data-access/src';
import { filter, map, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediadoraGuard {
  consultRules$ = this.store.select(ConsultationSelectors.selectConsultRules);

  constructor(private store: Store, private alertService: AlertService) {}

  canActivate() {
    return this.consultRules$.pipe(
      tap((rules) => {
        if (!rules) {
          this.store.dispatch(ConsultationActions.loadConsultOpeningRules());
        }
      }),
      filter((rules) => !!rules),
      map((rules) => {
        if (!rules) {
          this.alertService.showAlert(
            'error',
            'No se ha podido comprobar el estado del servicio de consultas',
            10000
          );
          return false;
        }
        if (
          !rules['area-professional-relation']['mediadora-familiar'] &&
          !rules['area-professional-relation']['coordinador-gestor']
        ) {
          this.alertService.showAlert(
            'info',
            'Aún no tienes un gestor asignado.',
            10000
          );
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}

export const canActivateMediadora: CanActivateFn = () => {
  return inject(MediadoraGuard).canActivate();
};
