import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ArticleResource,
  ArticleResourceResponse,
  Article,
  ArticlesResponse,
  GlossaryWord,
  GlossaryWordResponse,
  SelectedUser,
  ServiceReceiver,
  FormativeAreaInfo,
  ArticleResponse,
  FormativeAreaInfoResponse,
  FormativeAreaResponse,
} from '@nai-libs/data-access';
import { Observable, catchError, map, throwError } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, User } from '@nai-libs/data-access';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  /**
   * Método para obtener los artículos de interés de un cliente
   * @return Observable<Article[]>
   */
  fetchSuggestedArticles(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Article[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ArticlesResponse>(
        `${this.env.apiUrl}health/fetch-three-formative-articles?${params}`
      )
      .pipe(
        map((response) =>
          response['formative-articles'].length > 0
            ? response['formative-articles']
            : []
        ),
        catchError((err) => throwError(() => err))
      );
  }

  fetchAreaList(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FormativeAreaInfo[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };
    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<FormativeAreaInfoResponse>(
        `${this.env.apiUrl}health/fetch-applicable-formative-areas?${params}`
      )
      .pipe(
        map((res) => (res['formative-areas'] ? res['formative-areas'] : [])),
        catchError((err) => throwError(() => err))
      );
  }

  fetchArea(
    id: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FormativeAreaResponse> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': id,
    };
    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<FormativeAreaResponse>(
        `${this.env.apiUrl}health/fetch-specific-formative-area?${params}`
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  fetchArticle(
    id: string,
    area: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<Article[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': area,
      'article-code': id,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ArticleResponse>(
        `${this.env.apiUrl}health/fetch-specific-formative-area-article?${params}`
      )
      .pipe(
        map((response) => {
          return [
            {
              ...response['formative-article'],
              relatedArticles: response['suggested-articles'].map(
                (article) => article?.codigo 
              ).filter(article => !!article)
            },
            ...response['suggested-articles'].filter(article => !!article),
          ];
        }),
        catchError((err) => throwError(() => err))
      );
  }

  fetchArticleResources(
    id: string,
    area: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<ArticleResource[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': area,
      'article-code': id,
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<ArticleResourceResponse>(
        `${this.env.apiUrl}health/fetch-specific-formative-area-article-resources?${params}`
      )
      .pipe(
        map((res) => res['article-resources']),
        catchError((err) => throwError(() => err))
      );
  }

  fetchGlossaryWord(
    NANDACode: string,
    articleCode: string,
    word: string,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<GlossaryWord> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': NANDACode,
      'article-code': articleCode,
      word,
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .get<GlossaryWordResponse>(
        `${this.env.apiUrl}health/fetch-formative-area-glossary-word?${params}`
      )
      .pipe(
        map((res) => res['glossary-word-requested']),
        catchError((err) => throwError(() => err))
      );
  }

  markArticleAsRead(
    NANDACode: string,
    articleCode: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': NANDACode,
      'article-code': articleCode,
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post(
        `${this.env.apiUrl}documentation/save-read-formative-article`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  rateArticle(
    NANDACode: string,
    articleCode: string,
    rating: number,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'NANDA-code': NANDACode,
      'article-code': articleCode,
      'familiar-email': user['logged-user']['e-mail'],
      rating,
    };

    return this.http
      .post(
        `${this.env.apiUrl}documentation/formative-article/set-rating`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  likeArticle(
    like: boolean,
    NANDACode: string,
    articleCode: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ) {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      'NANDA-code': NANDACode,
      'article-code': articleCode,
      'like?': like,
    };

    return this.http.post(
      `${this.env.apiUrl}documentation/formative-article/set-like`,
      paramsObj
    );
  }
}
