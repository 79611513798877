import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, withLatestFrom } from 'rxjs';

import { Store } from '@ngrx/store';

import { UserSelectors } from '@nai-libs/user/data-access';
import * as PlanActions from './plan.actions';
import { PlanService } from './plan.service';

@Injectable()
export class PlanEffects {
  constructor(
    private actions$: Actions,
    private planService: PlanService,
    private store: Store
  ) {}

  loadPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.loadPlans),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      concatMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser)
          return of(PlanActions.loadPlansFailure());

        return this.planService.fetchPlans(serviceReceiver, selectedUser).pipe(
          map((plans) => PlanActions.loadPlansSuccess({ plans })),
          catchError(() => of(PlanActions.loadPlansFailure()))
        );
      })
    )
  );

  setPlanPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.setPlanPreferences),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      concatMap(([{ plans }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser)
          return of(PlanActions.setPlanPreferencesFailure());

        return this.planService
          .setPlanPreferences(serviceReceiver, selectedUser, plans)
          .pipe(
            map((_) => PlanActions.setPlanPreferencesSuccess()),
            catchError(() => of(PlanActions.loadPlansFailure()))
          );
      })
    )
  );

  setPlanPreferencesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.setPlanPreferencesSuccess),
      map((_) => PlanActions.loadPlans())
    )
  );

  loadHealthPlanProgress$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PlanActions.loadHealthPlanProgress),
    withLatestFrom(
      this.store.select(UserSelectors.selectServiceReceiver),
      this.store.select(UserSelectors.selectSelectedUser)
    ),
    concatMap(([, serviceReceiver, selectedUser]) => {
      if (!serviceReceiver || !selectedUser)
        return of(PlanActions.loadHealthPlanProgressFailure());

      return this.planService.getHealthPlanProgress(serviceReceiver, selectedUser).pipe(
        map((healthPlanProgress) => PlanActions.loadHealthPlanProgressSuccess({ healthPlanProgress })),
        catchError(() => of(PlanActions.loadHealthPlanProgressFailure()))
      );
    })
  )
);


loadHealthPlanSummary$ = createEffect(() =>
this.actions$.pipe(
  ofType(PlanActions.loadHealthPlanSummary),
  withLatestFrom(
    this.store.select(UserSelectors.selectServiceReceiver),
    this.store.select(UserSelectors.selectSelectedUser)
  ),
  concatMap(([, serviceReceiver, selectedUser]) => {
    if (!serviceReceiver || !selectedUser)
      return of(PlanActions.loadHealthPlanSummarysFailure());

    return this.planService.getHealthPlanSummary(serviceReceiver, selectedUser).pipe(
      map((healthPlanSummary) => PlanActions.loadHealthPlanSummarySuccess({ healthPlanSummary })),
      catchError(() => of(PlanActions.loadHealthPlanSummarysFailure()))
    );
  })
)
);
}
