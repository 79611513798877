import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';
import { filter, from, map, mergeMap, Observable, switchMap, toArray } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setItem<T>(key: string, _value: T): Observable<T> {
    const value = this.serialize(_value);
    return from(Preferences.set({ key, value })).pipe(map(() => _value));
  }

  getItem<T>(key: string): Observable<T | null> {
    return from(Preferences.get({ key })).pipe(
      map(({ value }: GetResult) => {
        if (value == null) {
          return null;
        }
        return this.unserialize<T>(value);
      })
    );
  }

  removeItem(key: string): Observable<void> {
    return from(Preferences.remove({ key })).pipe(map(() => {}));
  }
  removeItems(prefix: string): Observable<void> {
    return this.getKeys(prefix).pipe(
      mergeMap((keys) =>
        from(keys).pipe(mergeMap((key) => this.removeItem(key)))
      )
    );
  }

  private serialize(data: unknown): string {
    return JSON.stringify(data);
  }

  private unserialize<T>(data: string): T {
    return JSON.parse(data) as T;
  }

  getKeys(prefix: string): Observable<string[]> {
    return from(Preferences.keys()).pipe(
      map((keys) => keys?.keys.filter((key) => key.startsWith(prefix)))
    );
  }

  getItems<T>(prefix: string): Observable<(T | null)[]> {
    return this.getKeys(prefix).pipe(
      mergeMap((keys) =>
        from(keys).pipe(mergeMap((key) => this.getItem<T>(key)))
      ),
      toArray()
    );
  }
}
