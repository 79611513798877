import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  HealthPlanProgress,
  HealthPlanProgressResponse,
  HealthPlanSummaryItem,
  HealthPlanSummaryResponse,
  Plan,
  PlanResponse,
  SelectedUser,
  ServiceReceiver
} from '@nai-libs/data-access';
import { Observable, map } from 'rxjs';

import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  fetchPlans(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<any> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<PlanResponse>(`${this.env.apiUrl}health/health-plan?${params}`)
      .pipe(map((res) => res));
  }

  setPlanPreferences(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    plans: Plan[],
  ) {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'health-plan': plans
    };

    return this.http.post(
      `${this.env.apiUrl}health/health-plan/update`,
      paramsObj
    );
  }

  getHealthPlanProgress(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<HealthPlanProgress[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<HealthPlanProgressResponse>(`${this.env.apiUrl}health/fetch-health-plan/progress?${params}`)
      .pipe(map((res) => res['plan-progress']));

  }
  getHealthPlanSummary(
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<HealthPlanSummaryItem[]> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<HealthPlanSummaryResponse>(`${this.env.apiUrl}health/fetch-health-plan/summary?${params}`)
      .pipe(map((res) => res['progress-history']));

  }
  /* curl --request GET \
  --url http://localhost:3000/api-nf/health/fetch-health-plan/summary \
  --header 'Content-Type: application/json' \
  --header 'authorization: Token eyJhbGciOiJIUzUxMiJ9.eyJjbGllbnQiOiJmYW1pbHkudWJpa2FyZS5pbyIsImVtYWlsIjoianl0MzRAZ21haWwuY29tIiwicm9sZSI6IkNlcmNhbmEtdXNlciIsImV4cCI6MjM0MDM0ODM0Nn0.L3VsdccWN9WsoUbZS9x2_pEQAzKOY8OH7pLUpAWE2JDlWRBkfE6cOKZtP8pdwVY5aiVpoDu74WHO_f-9kM7LVA' \
  --data '{"client-phone": "62d13c91-e993-42f8-9bd0-c382702fc919"}'
 */


}
