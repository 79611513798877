import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'nai-care-plan-configurator-section',
  template: `
    <div class="configurator-container">
      <div class="header-container">
        <div class="header-content">
          <img [src]="sectionIcon" />
          <p class="configurator-title">{{ title }}</p>
        </div>
        <div class="toggle-container">
          <ion-toggle class="custom-toggle" mode="ios" [checked]="active" (ionChange)="toggle.emit(!active)"> </ion-toggle>
        </div>
      </div>
      <p class="configurator-subtitle">{{ subtitle }}</p>
    </div>
  `,
  standalone: true,
  imports: [
    TranslateModule,
    DynamicIconDirective,
    IonicModule,
    NgStyle,
    NgClass,
  ],
  styleUrls: ['./care-plan-configurator-section.component.scss'],
})
export class CarePlanConfiguratorSectionComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() sectionIcon = '/assets/icons/plan.svg';
  @Input() active = false;

  @Output() toggle = new EventEmitter<boolean>()

}
