import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MEDICATION_REDUCER_KEY,
  medicationReducer,
} from './+state/medication.reducer';
import { MedicationEffects } from './+state/medication.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MEDICATION_REDUCER_KEY, medicationReducer),
    EffectsModule.forFeature([MedicationEffects]),
  ],
})
export class MedicationDataAccessModule {}
