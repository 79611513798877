export interface FamiliarMember {
  email: string;
  'access-date': Date;
  'familiar-eid': number;
  'first-name': string;
  'last-name': string;
  'last-name2': string;
  'nf-account?'?: boolean;
  phone: string;
  relation: string;
  'invitation-send?'?: boolean;
}

export interface FamiliarMembersResponse {
  'client-phone': number;
  familiars: FamiliarMember[];
}

export interface NewMember {
  'first-name': string;
  'last-name': string;
  'last-name2'?: string;
  relation: string;
  email: string;
  phone: string;
}
