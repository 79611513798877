import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import * as ArticleActions from './article.actions';
import * as ArticleSelectors from './article.selectors';

import { Article } from '@nai-libs/data-access';
import { Store } from '@ngrx/store';
import { ArticleService } from './article.service';

import { UserActions, UserSelectors } from '@nai-libs/user/data-access';

@Injectable()
export class ArticleEffects {
  constructor(
    private actions$: Actions,
    private articleService: ArticleService,
    private store: Store
  ) {}

  loadSuggestedArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadSuggestedArticles),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser)
              
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.articleService
          .fetchSuggestedArticles(serviceReceiver, selectedUser)
          .pipe(
            map((articles: Article[]) =>
              ArticleActions.loadSuggestedArticlesSuccess({ articles })
            ),
            catchError(() => of(ArticleActions.loadSuggestedArticlesFailure()))
          );
      })
    )
  );

  loadAreaInfoList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadAreaInfoList),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(ArticleSelectors.selectFormativeAreas)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([{ forceFetch }, serviceReceiver, selectedUser, areaInfoList]) => {
          if (!serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          if (!forceFetch && areaInfoList)
            return of(
              ArticleActions.loadAreaInfoListSuccess({ areaInfoList })
            );

          return this.articleService
            .fetchAreaList(serviceReceiver, selectedUser)
            .pipe(
              map((areaInfoList) =>
                ArticleActions.loadAreaInfoListSuccess({ areaInfoList })
              ),
              catchError(() =>
                of(
                  ArticleActions.loadAreaInfoListFailure({
                    error: 'No se han podido recoger las areas formativas',
                  })
                )
              )
            );
        }
      )
    )
  );

  loadArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArea),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([{ id }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.articleService
          .fetchArea(id, serviceReceiver, selectedUser)
          .pipe(
            map((area) => ArticleActions.loadAreaSuccess({ area })),
            catchError(() => of(ArticleActions.loadAreaFailure()))
          );
      })
    )
  );

  loadArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArticle),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id, area }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.articleService
          .fetchArticle(id, area, serviceReceiver, selectedUser)
          .pipe(
            map((articles) =>
              ArticleActions.loadArticleSuccess({ articles })
            ),
            catchError((error) => {
              console.error(error);
              return of(ArticleActions.loadArticleFailure());
            })
          );
      })
    )
  );

  loadArticleResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadArticleResources),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ id, area }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }
        return this.articleService
          .fetchArticleResources(id, area, serviceReceiver, selectedUser)
          .pipe(
            map((resources) =>
              ArticleActions.loadArticleResourcesSuccess({ resources })
            ),
            catchError(() => of(ArticleActions.loadArticleResourcesFailure()))
          );
      })
    )
  );

  loadGlossaryWord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.loadGlosaryWord),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([{ NANDACode, articleCode, word }, serviceReceiver, selectedUser]) => {
          if (!serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          return this.articleService
            .fetchGlossaryWord(
              NANDACode,
              articleCode,
              word,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((glossaryWord) =>
                ArticleActions.loadGlosaryWordSuccess({ glossaryWord })
              ),
              catchError(() => of(ArticleActions.loadGlosaryWordFailure()))
            );
        }
      )
    )
  );

  markArticleAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.markArticleAsRead),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([{ NANDACode, articleCode }, user, serviceReceiver, selectedUser]) => {
          if (!user || !serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          return this.articleService
            .markArticleAsRead(
              NANDACode,
              articleCode,
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) => ArticleActions.markArticleAsReadSuccess()),
              catchError((error) =>
                of(ArticleActions.markArticleAsReadFailure(error))
              )
            );
        }
      )
    )
  );

  rateArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.rateArticle),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { NANDACode, articleCode, rating },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          return this.articleService
            .rateArticle(
              NANDACode,
              articleCode,
              rating,
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) => ArticleActions.rateArticleSuccess({ NANDACode, articleCode })),
              catchError((error) =>
                of(ArticleActions.rateArticleFailure(error))
              )
            );
        }
      )
    )
  );

  rateArticleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.rateArticleSuccess),
      map(({ NANDACode, articleCode }) =>
        ArticleActions.loadArticle({ id: articleCode, area: NANDACode })
      )
    )
  );

  likeArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.likeArticle),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(
        ([
          { NANDACode, articleCode, like },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser) {
            return of(UserActions.logout());
          }
          return this.articleService
            .likeArticle(
              like,
              NANDACode,
              articleCode,
              user,
              serviceReceiver,
              selectedUser
            )
            .pipe(
              map((_) =>
                ArticleActions.likeArticleSuccess({ NANDACode, articleCode })
              ),
              catchError((error) =>
                of(ArticleActions.likeArticleFailure(error))
              )
            );
        }
      )
    )
  );

  likeArticleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActions.likeArticleSuccess),
      map(({ NANDACode, articleCode }) =>
        ArticleActions.loadArticle({ id: articleCode, area: NANDACode })
      )
    )
  );
}
