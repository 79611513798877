import { createReducer, on } from '@ngrx/store';

import * as GoalActions from './goal.actions';
import { Goal, GoalItem, RequestStatus } from '@nai-libs/shared/data-access/src';

export const GOAL_REDUCER_KEY = 'goal';

export interface GoalState {
  goal?: GoalItem;
  goalStatus: RequestStatus;
  threeMonthGoalsStatus: RequestStatus;
}

const initalState: GoalState = {
  goalStatus: { pending: false },
  threeMonthGoalsStatus: { pending: false },
};

export const goalReducer = createReducer(
  initalState,
  on(GoalActions.loadDailyGoal, (state) => ({
    ...state,
  })),
  on(
    GoalActions.loadDailyGoalSuccess, (state, { goal }) => ({
      ...state,
      goal,
      goalStatus: { pending: false },
    })
  ),
  on(GoalActions.loadDailyGoalFailure, (state) => ({
    ...state,
    goal: undefined,
    goalStatus: { pending: false },
  })),

    // Three month goals
    on(GoalActions.loadThreeMonthGoals, (state) => ({
      ...state,
      threeMonthGoalsStatus: { pending: true },
    })),
    on(
      GoalActions.loadThreeMonthGoalsSuccess,
      (state, { goalsByMonth }) => ({
        ...state,
        goalsByMonth,
        threeMonthGoalsStatus: { pending: false },
      })
    ),
    on(GoalActions.loadThreeMonthGoalsFailure, (state) => ({
      ...state,
      threeMonthGoalsStatus: { pending: false },
    })),



);
