import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import * as NoticeBoardActions from './notice-board.actions';
import * as NoticeBoardSelectors from './notice-board.selectors';

import {
  NoticeBoardArticle,
  NoticeBoardArticlePreview,
} from '@nai-libs/data-access';
import { NoticeBoardService } from './notice-board.service';
import { Store } from '@ngrx/store';
import { UserSelectors } from '@nai-libs/user/data-access/src';

@Injectable()
export class NoticeBoardEffects {
  constructor(
    private actions$: Actions,
    private noticeBoardService: NoticeBoardService,
    private store: Store
  ) {}

  loadNoticeBoardPreviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NoticeBoardActions.loadNoticeBoardPreviews),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(NoticeBoardSelectors.selectNoticeBoardPreviews),
              this.store.select(UserSelectors.selectServiceReceiver)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([, noticeBoardArticlePreviews, serviceReceiver]) => {
        if (noticeBoardArticlePreviews)
          return of(
            NoticeBoardActions.loadNoticeBoardPreviewsSuccess({
              noticeBoardArticlePreviews,
            })
          );
        return this.noticeBoardService.fetchNoticeBoardArticlePreviews(serviceReceiver).pipe(
          map((noticeBoardArticlePreviews: NoticeBoardArticlePreview[]) =>
            NoticeBoardActions.loadNoticeBoardPreviewsSuccess({
              noticeBoardArticlePreviews,
            })
          ),
          catchError(() =>
            of(NoticeBoardActions.loadNoticeBoardPreviewsFailure())
          )
        );
      })
    )
  );

  loadNoticeBoardArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NoticeBoardActions.loadNoticeBoardArticle),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(
                NoticeBoardSelectors.selectNoticeBoardArticleMap
              ),
              this.store.select(UserSelectors.selectServiceReceiver)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(
        ([{ forceFetch, id }, noticeBoardArticleMap, serviceReceiver]) => {
          if (
            !forceFetch &&
            noticeBoardArticleMap &&
            id in noticeBoardArticleMap
          ) {
            return of(
              NoticeBoardActions.loadNoticeBoardArticleSuccess({
                noticeBoardArticle: noticeBoardArticleMap[id],
              })
            );
          }
          return this.noticeBoardService
            .fetchNoticeBoardArticle(id, serviceReceiver)
            .pipe(
              map((noticeBoardArticle: NoticeBoardArticle) =>
                NoticeBoardActions.loadNoticeBoardArticleSuccess({
                  noticeBoardArticle,
                })
              ),
              catchError(() =>
                of(NoticeBoardActions.loadNoticeBoardArticleFailure())
              )
            );
        }
      )
    )
  );
}
