import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { CarePlanConfiguratorComponent } from '../../../../../../libs/plan/features/plan-feature/src/lib/containers/care-plan-configurator.component';
import {
  first,
  map,
} from 'rxjs';
import { PlanSelectors } from 'libs/plan/data-access/src';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class PlansGuard {

  plans$ = this.store.select(PlanSelectors.selectPlansConfig);

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) {}

  canActivate() {
    return this.plans$
      .pipe(
      map((plans: any) => {
        if (plans && plans['force-health-plan-configuration']) {
         this.dialog.open(CarePlanConfiguratorComponent, {
          data: {plans},
          maxHeight: '735px',
          maxWidth: '754px',
          autoFocus: false,
          panelClass: ['dialog-panel', 'dialog-panel--large'],
        });
        return true;
      }
      return true;
    }),
    )
  }
}

export const canActivatePlan: CanActivateFn = () => {
  return inject(PlansGuard).canActivate();
};


