export interface GoalsResponse {
  'goals': GoalItem[];
}
export interface Goal {
  day: string;
  month: string;
  year: string;
  goal: GoalItem[];
}

export interface GoalItem {
  id: string;
  date?: string;
  period?: 'p1' | 'p2' | 'p3' | 'p4' | 'p5' | 'p6' | 'p7';
  title?: string;
  "sub-title"?: string;
  icon: string;
  description?: string;
  'done?': boolean;
}

export interface GoalsByMonth {
  month: string;
  year: string;
  goals: GoalItem[];
}
