import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  ContactInfo,
  Service,
  ServiceCategory,
  ServiceLocationMarkerResponse,
} from '@nai-libs/data-access';
import {
  ServiceHistoryResponse,
  ServiceInfo,
  ServiceReceiver,
  ServiceStatus,
} from '@nai-libs/shared/data-access/src';
import { Observable, map } from 'rxjs';
// import { SERVICE_HISTORY } from './data/service-history';
// import { SERVICE_LOCATIONS } from './data/service-markers';
// import { SERVICE_CATEGORY } from './data/services-category';

@Injectable({ providedIn: 'root' })
export class EdercareService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig,
    private router: Router
  ) {}

  fetchAvailableServices(): Observable<ServiceCategory[]> {
    return this.http.get<ServiceCategory[]>(
      `${this.env.baseUrl}/services/available-services`
    );
    // return of(SERVICE_CATEGORY);
  }

  fetchRecommendedServices(
    serviceReceiver?: ServiceReceiver
  ): Observable<ServiceInfo[]> {
    let params = new HttpParams();
    if (serviceReceiver)
      params = params.append('client-phone', serviceReceiver['client-phone']);
    return this.http
      .get<{ services: ServiceInfo[] }>(
        `${this.env.apiUrl}services/recommended-available-services?${params}`
      )
      .pipe(map((response) => response.services));
  }

  fetchServiceLocationMarkers(
    ZIPCode: string,
    service: Service
  ): Observable<ServiceLocationMarkerResponse> {
    const paramsObj = {
      CP: ZIPCode,
      serviceCode: service.info.code,
      modality: service.modality,
    };

    const params = new URLSearchParams(paramsObj).toString();
    return this.http.get<ServiceLocationMarkerResponse>(
      `${this.env.baseUrl}/services/find-zone?${params}`
    );

    //  return of(SERVICE_LOCATIONS);
  }

  fetchCart(): Observable<Service[]> {
    return this.http.get<Service[]>(`${this.env.apiUrl}services/fetch-basquet`);
  }

  saveToCart(service: Service) {
    const params = {
      service,
    };
    return this.http.post(`${this.env.apiUrl}services/save-to-basket`, params);
  }

  removeServiceFromCart(ref: string): Observable<string> {
    const paramsObj = { ref };
    const params = new URLSearchParams(paramsObj).toString();
    return this.http
      .delete<Service[]>(`${this.env.apiUrl}services/delete-service?${params}`)
      .pipe(map((_) => ref));
  }

  saveCart(cart: Service[]) {
    const params = {
      cart,
    };
    return this.http.post<Service[]>(
      `${this.env.apiUrl}services/confirm-services`,
      params
    );
  }

  loadServiceHistory(
    filters: ServiceStatus[],
    page: number,
    pageSize: number,
    serviceReceiver: ServiceReceiver | undefined
  ): Observable<{ total: number; services: Service[] }> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    if (serviceReceiver)
      params = params.append('client-phone', serviceReceiver['client-phone']);
    if (filters.length) params = params.append('filters', filters.join(', '));

    return this.http
      .get<ServiceHistoryResponse>(
        `${this.env.apiUrl}services/get-my-services?${params}`
      )
      .pipe(
        map((response) => ({
          total: response.totalOffers,
          services: response.offers,
        }))
      );

    // return of(SERVICE_HISTORY);
  }

  loadService(serviceReceiver: ServiceReceiver | undefined, ref: string) {
    // service receiver mandatory
    let params = new HttpParams();
    params = params.append('ref', ref);
    if (serviceReceiver)
      params = params.append('client-phone', serviceReceiver['client-phone']);

    return this.http
      .get<ServiceHistoryResponse>(
        `${this.env.apiUrl}services/get-my-services?${params}`
      )
      .pipe(
        map((response) => ({
          total: response.totalOffers,
          services: response.offers,
        }))
      );

    // return of(SERVICE_HISTORY);
  }
  contact(contactInfo: ContactInfo) {
    const params = {
      contactInfo,
    };
    return this.http.post(`${this.env.baseUrl}/contact-me`, params);
  }

  getRoute(route: string) {
    return this.router.url.startsWith('/edercare')
      ? '/edercare/' + route
      : '/services/edercare/' + route;
  }
}
