import { HealthPlanProgress, HealthPlanSummaryItem, Plan, PlanResponse } from '@nai-libs/shared/data-access/src';
import { createAction, props } from '@ngrx/store';

export const loadPlans = createAction('[Plan] Load plans');
export const loadPlansSuccess = createAction(
  '[Plan] Load members success',
  props<{ plans: PlanResponse }>()
);
export const loadPlansFailure = createAction('[Plan] Load plan error');

export const setPlanPreferences = createAction('[Plan] Set plan preferences', props<{ plans: Plan[] }>());
export const setPlanPreferencesSuccess = createAction(
  '[Plan] Set plan preferences success'
);
export const setPlanPreferencesFailure = createAction('[Plan] Set plan preferences error');

//Load Health Plan Progress
export const loadHealthPlanProgress = createAction('[Plan] Load health plan progress');
export const loadHealthPlanProgressSuccess = createAction(
  '[Plan] Load health plan progress success',
  props<{ healthPlanProgress: HealthPlanProgress[] }>()
);
export const loadHealthPlanProgressFailure = createAction('[Plan] Load health plan progress error');

//Load Health Plan Summary
export const loadHealthPlanSummary = createAction('[Plan] Load health plan summary');
export const loadHealthPlanSummarySuccess = createAction(
  '[Plan] Load health plan summary success',
  props<{ healthPlanSummary: HealthPlanSummaryItem[] }>()
);
export const loadHealthPlanSummarysFailure = createAction('[Plan] Load health plan summary error');

