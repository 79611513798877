import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';

import * as DGIActions from './dgi.actions';

import { Platform } from '@ionic/angular';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig, DGIResult } from '@nai-libs/data-access';
import { FileService } from '@nai-libs/shared/utility/src';
import { DGISelectors } from '../..';
import { DGIService } from './dgi.service';

@Injectable()
export class DGIEffects {
  constructor(
    private actions$: Actions,
    @Inject(APP_CONFIG) private env: AppConfig,
    private store: Store,
    private DGIService: DGIService,
    private platform: Platform,
    private fileService: FileService
  ) {}

  // Historia clínica
  loadLastDGI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.loadLastDGI),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.DGIService.getLastDGI(serviceReceiver, selectedUser).pipe(
          map((dgiDate) => DGIActions.loadLastDGISuccess({ dgiDate })),
          catchError(() => of(DGIActions.loadLastDGIError()))
        );
      })
    )
  );

  // loadLastDGISuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DGIActions.loadLastDGISuccess),
  //     withLatestFrom(this.store.select(UserSelectors.selectUser)),
  //     map(([{ dgiDate }]) => {
  //       if (dgiDate) {
  //         return DGIActions.loadDGIResults({ dgiDate });
  //       } else {
  //         return DGIActions.loadDGIResultsFailure({ error: 'No dgi date' });
  //       }
  //     })
  //   )
  // );

  // DGI
  loadDGI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.loadDGI),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());

        return this.DGIService.getDGI(user, serviceReceiver, selectedUser).pipe(
          map((dgiDate) => DGIActions.loadDGISuccess({ dgiDate })),
          catchError(() => of(DGIActions.loadDGIError()))
        );
      })
    )
  );

  openDGI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.openDGI),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ dgiDate }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.DGIService.getDGILink(
          dgiDate,
          serviceReceiver,
          selectedUser
        ).pipe(
          switchMap((dgiLink: string) => {
            if (dgiLink) {
              return this.fileService.openFile(dgiLink, 'dgi.pdf').pipe(
                map(() => DGIActions.openDGISuccess({ dgiLink, dgiDate })),
                catchError(() => [DGIActions.openDGIFailure()])
              );
            }
            return [DGIActions.openDGIFailure()];
          }),
          catchError(() => of(DGIActions.openDGIFailure()))
        );
      })
    )
  );

  openDGISuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.openDGISuccess),
      map(({ dgiDate }) => {
        return DGIActions.markDGIAsRead({ dgiDate });
      })
    )
  );

  downloadDGI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.downloadDGI),
      withLatestFrom(
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ dgiDate }, serviceReceiver, selectedUser]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        return this.DGIService.getDGILink(
          dgiDate,
          serviceReceiver,
          selectedUser
        ).pipe(
          switchMap((dgiLink: string) => {
            if (dgiLink) {
              return this.fileService
                .downloadFile(dgiLink, 'informe-personal.pdf')
                .pipe(
                  map(() =>
                    DGIActions.downloadDGISuccess({ dgiLink, dgiDate })
                  ),
                  catchError(() => {
                    return [DGIActions.downloadDGIFailure()];
                  })
                );
            }
            return [DGIActions.downloadDGIFailure()];
          }),
          catchError(() => of(DGIActions.downloadDGIFailure()))
        );
      })
    )
  );

  downloadDGISuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.downloadDGISuccess),
      switchMap(({ dgiLink, dgiDate }) => [
        DGIActions.markDGIAsRead({ dgiDate }),
      ])
    )
  );

  markDGIAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.markDGIAsRead),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ dgiDate }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());
        return this.DGIService.markDGIAsRead(
          dgiDate,
          user,
          serviceReceiver,
          selectedUser
        ).pipe(
          map((res) =>
            res
              ? DGIActions.markDGIAsReadSuccess()
              : DGIActions.markDGIAsReadFailure()
          ),
          catchError(() => of(DGIActions.markDGIAsReadFailure()))
        );
      })
    )
  );

  loadDGIResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DGIActions.loadDGIResults),
      mergeMap((_) =>
        of(_)
          .pipe(
            withLatestFrom(
              this.store.select(UserSelectors.selectServiceReceiver),
              this.store.select(UserSelectors.selectSelectedUser),
              this.store.select(DGISelectors.selectDGIResults)
            )
          )
          .pipe(map((latest) => latest))
      ),
      switchMap(([{ dgiDate }, serviceReceiver, selectedUser, dgiResults]) => {
        if (!serviceReceiver || !selectedUser) return of(UserActions.logout());
        if (dgiResults) {
          return of(DGIActions.loadDGIResultsSuccess({ dgiResults }));
        }
        return this.DGIService.getDGIResults(
          serviceReceiver,
          selectedUser,
          dgiDate
        ).pipe(
          map((dgiResults: DGIResult[]) =>
            DGIActions.loadDGIResultsSuccess({ dgiResults })
          ),
          catchError(() =>
            of(
              DGIActions.loadDGIResultsFailure({
                error: 'No se ha podido recoger la historia clínica',
              })
            )
          )
        );
      })
    )
  );
}
