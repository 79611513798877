import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicationService } from './medication.service';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access/src';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as MedicationActions from './medication.actions';
import { DGIResult } from '@nai-libs/shared/data-access/src';

@Injectable()
export class MedicationEffects {
  constructor(
    private actions$: Actions,
    private medicationService: MedicationService,
    private store: Store
  ) {}
  loadMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicationActions.loadMedication),
      withLatestFrom(this.store.select(UserSelectors.selectServiceReceiver)),
      switchMap(([, serviceReceiver]) => {
        if (!serviceReceiver)
          return of(MedicationActions.loadMedicationFailure({}));

        return this.medicationService.fetchMedication(serviceReceiver).pipe(
          map((medication: DGIResult[]) =>
            MedicationActions.loadMedicationSuccess({ medication })
          ),
          catchError(() =>
            of(
              MedicationActions.loadMedicationFailure({
                error: 'No se ha podido recoger la historia clínica',
              })
            )
          )
        );
      })
    )
  );
}
