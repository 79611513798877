  export interface ProfessionalResponse {
  'service-givers': Professional[];
}
export interface CustomerSupportProfessional {
  role: string;
  description: string;
  email?: string;
  'consult?': boolean;
  phone?: string;
  reference: string;
  'professional-eid': number;
  identifier: string;
  'intervention-date': Date;
  'img-link': {
    mobile: string;
    desktop: string;
  };
  image?: string;
}

export interface Professional {
  'professional-eid': number;
  identifier: string;
  role: string;
  reference: string;
  'consult?': boolean;
  'intervention-date': Date;
  image?: string;
}

export interface ProfessionalResponse {
  'service-givers': Professional[];
}

export interface Caregiver {
  role: string;
  schedule: {
    data: string[][];
    description: string[];
  };
  email: string;
  'contact-phone': { landline: string | null; mobile: string };
  activity: string;
  'caregiver-eid': string;
  identifier: string; // map indentifier to identifier
  indentifier: string;
  'img-link': { desktop: string | null; mobile: string | null };
  'activity-label': string;
  image?: string;
}
export interface CaregiverResponse {
  'formal-caregivers': Caregiver[];
}
