import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Observable,
  catchError,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import { SELECTED_USER_KEY, TOKEN_KEY, UserService } from './user.service';

import { Action, Store } from '@ngrx/store';

import { StorageService } from '@nai-libs/utility';

// Material
import { MatDialog } from '@angular/material/dialog';

import { NavController } from '@ionic/angular';
import { AlertService } from '@nai-libs/shared/utility/src';
import { PasswordChangeSuccessPageComponent } from '@nai-libs/user/features/change-password-feature/src/lib/standalone/password-change-success-page/password-change-success-page.component';
import { PasswordResetSuccessPageComponent } from '@nai-libs/user/features/new-password-feature/src/lib/standalone/password-reset-success-page/password-reset-success-page.component';
import { PasswordRecoverySuccessPageComponent } from '@nai-libs/user/features/password-recovery-feature/src/lib/standalone/password-recovery-success-page/password-recovery-success-page.component';
import { CustomTranslateService } from '@nai-libs/utility';

import { Capacitor } from '@capacitor/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import { ClockInActions } from '@nai-libs/clock-in/data-access/src';
import { NotificationActions } from '@nai-libs/notification/data-access/src';
import { PushNotificationActions } from '@nai-libs/push-notifications/data-access';
import { AppConfig, SelectedUser } from '@nai-libs/shared/data-access/src';
import { TranslateService } from '@ngx-translate/core';

// Components

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private alert: AlertService,
    private dialog: MatDialog,
    private store: Store,
    private storage: StorageService,
    private navCtrl: NavController,
    private customTranslateService: CustomTranslateService,
    private translateService: TranslateService,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),

      switchMap(({ email, password }) =>
        this.userService.login(email, password).pipe(
          map((token: string) => {
            this.storage.setItem(TOKEN_KEY, token);
            return UserActions.loginSuccess();
          }),
          catchError((_) => {
            return of(UserActions.loginFailure());
          })
        )
      )
    )
  );

  autologin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.autologin),
      map(({ token }) => {
        this.storage.setItem(TOKEN_KEY, token);
        return UserActions.loginSuccess();
      })
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),

      map((_) =>
        UserActions.loadUserData({
          isLogin: true,
        })
      )
    )
  );

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserData),
      concatMap(({ isLogin }) =>
        this.userService.fetchUserData().pipe(
          map((user) => UserActions.loadUserDataSuccess({ user })),
          catchError((error) => {
            return of(UserActions.loadUserDataFailure({ error }));
          })
        )
      )
    )
  );
  loadUserDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loadUserDataFailure),
        tap(({ error }) => console.error(error))
      ),
    { dispatch: false }
  );
  loadUserDataSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserDataSuccess),
      withLatestFrom(
        this.storage.getItem(SELECTED_USER_KEY),
        this.store.select(UserSelectors.selectLoginRoute)
      ),
      concatMap(([{ user }, selectedUser, loginRoute]) => {
        if (!user) return of(UserActions.logout());

        if (user.token) this.storage.setItem(TOKEN_KEY, user.token);

        // Request push notifications permission
        if (Capacitor.getPlatform() !== 'web') {
          this.store.dispatch(PushNotificationActions.requestPermission());
        }
        // Set user language
        this.customTranslateService.translate();

        // Set selected user
        const defaultUser: SelectedUser = {
          'user-id': user['logged-user']['e-mail'],
          'user-role': user['logged-user']['naiha-fam-access'],
          avatar: user['logged-user'].avatar,
          identifier: user['logged-user'].identifier,
          id: user['logged-user'].id,
          image: user['logged-user'].image, 
        };

        if (loginRoute) {
          if (user['logged-user']['naiha-family']) {
            loginRoute =
              loginRoute === '/edercare' || loginRoute === '/'
                ? '/'
                : '/services' + loginRoute;
          }

          this.navCtrl.navigateRoot(loginRoute);
        }
        const actions = [
          UserActions.clearLoginRedirectRoute(),
          UserActions.setServiceReceiver({
            serviceReceiver: Array.isArray(user['service-receiver'])
              ? user['service-receiver'][0]
              : user['service-receiver'],
          }),
          UserActions.setSelectedUser({
            selectedUser: (selectedUser as SelectedUser) ?? defaultUser,
          }),
        ];

        return of(...actions);
      })
    )
  );

  setServiceReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setServiceReceiver),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([{ serviceReceiver, routeOnSuccess }, user]) => {
        if (!user) return of(UserActions.logout());

        if (!serviceReceiver) {
          return this.userService.getSavedServiceReceiver().pipe(
            switchMap((savedSr) => {
              if (savedSr && Array.isArray(user['service-receiver'])) {
                serviceReceiver = user['service-receiver'].find(
                  (s) => s['client-phone'] === savedSr
                );
              }
              if (!serviceReceiver) {
                if (Array.isArray(user['service-receiver'])) {
                  serviceReceiver = user['service-receiver'][0];
                } else {
                  serviceReceiver = user['service-receiver'];
                }
              }

              if (!serviceReceiver)
                return of(UserActions.setServiceReceiverFailure());

              return of(
                UserActions.setServiceReceiverSuccess({
                  serviceReceiver,
                  routeOnSuccess,
                })
              );
            })
          );
        }
        return of(
          UserActions.setServiceReceiverSuccess({
            serviceReceiver,
            routeOnSuccess,
          })
        );
      })
    )
  );

  setServiceReceiverSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setServiceReceiverSuccess),
        withLatestFrom(this.store.select(UserSelectors.selectLoggedUserRole)),
        tap(([{ serviceReceiver, routeOnSuccess }, userRole]) => {
          if (serviceReceiver)
            this.userService.persistServiceReceiver(serviceReceiver);

          // Load interventions
          if (userRole === 'Cuidadora formal') {
            this.store.dispatch(ClockInActions.loadActiveIntervention());
          }
          // Load notifications
          if (this.env.features.notifications) {
            this.store.dispatch(NotificationActions.loadNotifications());
          }
        })
      ),
    { dispatch: false }
  );

  setServiceReceiverFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setServiceReceiverFailure),
        withLatestFrom(this.store.select(UserSelectors.selectLoggedUserRole)),
        tap(([, userRole]) => {
          if (userRole === 'Cuidadora formal')
            this.alert.showAlert(
              'warning',
              'Aún no tienes pacientes a tu cargo'
            );
        })
      ),
    { dispatch: false }
  );

  setSelectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setSelectedUser),
      map((selectedUser) => UserActions.setSelectedUserSuccess(selectedUser))
    )
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginFailure),
        tap((_) => {
          this.alert.showAlert('error', 'Usuario o contraseña incorrectos.');
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        switchMap((_) => this.userService.logout()),
        map((_) => this.navCtrl.navigateRoot('/login'))
      ),
    { dispatch: false }
  );

  sendPasswordRecoveryEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendPasswordRecoveryEmail),
      switchMap(({ email }) =>
        this.userService.sendPasswordRecoveryEmail(email).pipe(
          map((_) => UserActions.sendPasswordRecoveryEmailSuccess()),
          catchError((error) =>
            of(UserActions.sendPasswordRecoveryEmailFailure(error))
          )
        )
      )
    )
  );

  sendPasswordRecoveryEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.sendPasswordRecoveryEmailSuccess),
        tap(() => {
          this.dialog.open(PasswordRecoverySuccessPageComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-send-password-recovery-success',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  sendPasswordRecoveryEmailFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.sendPasswordRecoveryEmailFailure),
        tap(({ error }) => {
          this.alert.showAlert(
            'error',
            this.translateService.instant('USER.PASSWORD_RECOVERY.EMAIL.ERROR')
          );
        })
      ),
    { dispatch: false }
  );

  saveNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveNewPassword),
      switchMap(({ email, password, tempPassword }) =>
        this.userService.saveNewPassword(email, password, tempPassword).pipe(
          map((_) => UserActions.saveNewPasswordSuccess()),
          catchError((error) => of(UserActions.saveNewPasswordFailure(error)))
        )
      )
    )
  );

  saveNewPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.saveNewPasswordSuccess),
        tap(() => {
          this.dialog.open(PasswordResetSuccessPageComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-full',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  changePass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePass),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      switchMap(([{ newPass, oldPass }, user, serviceReceiver]) => {
        if (!user || !serviceReceiver) return of(UserActions.logout());
        return this.userService
          .changePassword(oldPass, newPass, user, serviceReceiver)
          .pipe(
            map((_) => UserActions.changePassSuccess()),
            catchError(() => of(UserActions.changePassFailure()))
          );
      })
    )
  );

  changePassSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changePassSuccess),
        tap(() => {
          this.dialog.open(PasswordChangeSuccessPageComponent, {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
            panelClass: 'dialog-full',
            autoFocus: false,
          });
        })
      ),
    { dispatch: false }
  );

  acceptUsingTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptUsingTerms),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([, user]) => {
        if (!user) return of(UserActions.logout());

        return this.userService.acceptUsingTerms(user).pipe(
          map(() => UserActions.acceptUsingTermsSuccess()),
          catchError((error) =>
            of(UserActions.acceptUsingTermsFailure({ error }))
          )
        );
      })
    )
  );

  acceptUsingTermsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptUsingTermsSuccess),
      switchMap(() => {
        this.store.dispatch(UserActions.setLoginRedirectRoute({ route: '/' }));
        return of(UserActions.loadUserData({ isLogin: true }));
      })
    )
  );

  acceptUsingTermsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.acceptUsingTermsFailure),
      switchMap(() => {
        return of(UserActions.logout());
      })
    )
  );

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeLanguage),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ lang }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());

        return this.userService
          .changeLanguage(lang, user, serviceReceiver, selectedUser)
          .pipe(
            map((_) => UserActions.changeLanguageSuccess()),
            catchError((_) => of(UserActions.changeLanguageFailure()))
          );
      })
    )
  );

  changeLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeLanguageSuccess),
      switchMap(() => {
        return of(UserActions.loadUserData({ isLogin: false }));
      })
    )
  );

  changeAvatarColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeAvatarColor),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ color, mode }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser)
          return of(UserActions.logout());

        return this.userService
          .changeAvatarColor(user, serviceReceiver, selectedUser, color, mode)
          .pipe(
            map((_) => UserActions.changeAvatarColorSuccess()),
            catchError((_) => of(UserActions.changeAvatarColorFailure()))
          );
      })
    )
  );

  changeAvatarColorSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changeAvatarColorSuccess),
        tap((_) => {
          this.store.dispatch(UserActions.loadUserData({ isLogin: false }));
          this.alert.showAlert('success', 'Avatar actualizado');
        })
      ),
    { dispatch: false }
  );

  changeAvatarColorFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changeAvatarColorFailure),
        tap((_) => {
          this.alert.showAlert('error', 'Error al actualizar el avatar');
        })
      ),
    { dispatch: false }
  );

  editUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.editUser),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      switchMap(([{ editedUser }, user, serviceReceiver, selectedUser]) => {
        if (!user) return of(UserActions.logout());
        return this.userService
          .editUser(user, editedUser, serviceReceiver, selectedUser)
          .pipe(
            map((_) => UserActions.editUserSuccess()),
            catchError((_) => of(UserActions.editUserFailure()))
          );
      })
    )
  );

  editUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.editUserSuccess),
        tap((_) => {
          this.store.dispatch(UserActions.loadUserData({ isLogin: false }));
          this.alert.showAlert('success', 'Datos del perfil actualizados');
        })
      ),
    { dispatch: false }
  );

  editUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.editUserFailure),
        tap((_) => {
          this.alert.showAlert(
            'error',
            'Error al actualizar los datos del perfil'
          );
        })
      ),
    { dispatch: false }
  );

  changeSelectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeSelectedUser),
      withLatestFrom(this.store.select(UserSelectors.selectSelectedUser)),
      map(([{ selectedUser }, currentUser]) => {
        if (currentUser?.['user-id'] !== selectedUser['user-id']) {
          this.storage.setItem(SELECTED_USER_KEY, selectedUser);
          return UserActions.changeSelectedUserSuccess({ selectedUser });
        } else {
          return UserActions.changeSelectedUserFailure();
        }
      })
    )
  );

  changeSelectedUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changeSelectedUserSuccess),
        tap((_) => {
          window.location.reload();
        })
      ),
    { dispatch: false }
  );

  saveSignUpData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveSignUpData),
      map(({ user, routeOnSuccess }) =>
        UserActions.saveSignUpDataSuccess({ routeOnSuccess })
      )
    )
  );

  saveSignUpDataSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.saveSignUpDataSuccess),
        tap(({ routeOnSuccess }) => {
          if (routeOnSuccess) this.navCtrl.navigateForward(routeOnSuccess);
        })
      ),
    { dispatch: false }
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.signUp),
      withLatestFrom(this.store.select(UserSelectors.selectSignUpData)),
      switchMap(([{ password, routeOnSuccess }, user]) => {
        if (!user || !password)
          return of(
            UserActions.signUpFailure({
              error: {
                reason: 'No valid user provided',
                success: false,
              },
            })
          );
        return this.userService.signUp({ ...user, password }).pipe(
          map((clientPhone) =>
            UserActions.signUpSuccess({ clientPhone, routeOnSuccess })
          ),
          catchError((error) =>
            of(
              UserActions.signUpFailure({
                error: { reason: error.error.reason, success: false },
              })
            )
          )
        );
      })
    )
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.signUpSuccess),
        tap(({ routeOnSuccess }) => {
          if (routeOnSuccess) this.navCtrl.navigateForward(routeOnSuccess);
        })
      ),
    { dispatch: false }
  );

  signUpFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.signUpFailure),
        tap(({ error }) => {
          this.alert.showAlert(
            'error',
            error.reason
              ? error.reason
              : 'Error al crear el usuario, inténtalo más tarde.',
            10000
          );
        })
      ),
    { dispatch: false }
  );

  signUpServiceReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.signUpServiceReceiver),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([{ serviceReceiver }, user]) => {
        if (!user || !serviceReceiver)
          return of(
            UserActions.signUpServiceReceiverFailure({
              error: 'No valid service receiver provided',
            })
          );
        return this.userService.signUpServiceReceiver(serviceReceiver).pipe(
          map((token) =>
            UserActions.signUpServiceReceiverSuccess({
              token,
            })
          ),
          catchError((error) =>
            of(UserActions.signUpServiceReceiverFailure({ error }))
          )
        );
      })
    )
  );

  signUpServiceReceiverSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.signUpServiceReceiverSuccess),
      map(({ token }) => {
        this.storage.setItem(TOKEN_KEY, token);
        return UserActions.loadUserData({ isLogin: false });
      })
    )
  );

  signUpServiceReceiverFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.signUpServiceReceiverFailure),
        tap((_) => {
          this.alert.showAlert(
            'error',
            'Error al crear la persona beneficiaria, inténtalo más tarde.',
            10000
          );
        })
      ),
    { dispatch: false }
  );

  validateEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.validateEmail),
      withLatestFrom(this.store.select(UserSelectors.selectSignUpData)),
      switchMap(([{ code, routeOnSuccess }, signUpData]) => {
        if (!signUpData || !signUpData.clientPhone)
          return of(
            UserActions.validateEmailFailure({ error: 'Missing sign up data' })
          );
        return this.userService
          .validateEmail(code, signUpData.clientPhone, signUpData.email)
          .pipe(
            map((token) =>
              UserActions.validateEmailSuccess({ token, routeOnSuccess })
            ),
            catchError((error) =>
              of(UserActions.validateEmailFailure({ error }))
            )
          );
      })
    )
  );

  validateEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.validateEmailSuccess),
      map(({ token }) => UserActions.autologin({ token }))
    )
  );

  loadTermsAndPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadTermsAndPolicy),

      concatMap((_) =>
        this.userService.fetchTermsAndPolicy().pipe(
          map((response) => {
            return UserActions.loadTermsAndPolicySuccess(response);
          }),
          catchError((_) => {
            return of(UserActions.loadTermsAndPolicyFailure());
          })
        )
      )
    )
  );
}
