import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  delay,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import * as GoalActions from './goal.actions';
import { GoalService } from './goal.service';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import { GoalItem, GoalsByMonth } from '@nai-libs/shared/data-access/src';
import { MatDialog } from '@angular/material/dialog';
import { DialogSuccessComponent } from '@nai-libs/shared/ui/src/lib/standalone/dialog-success/dialog-success.component';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class GoalEffects {
  constructor(
    private actions$: Actions,
    private goalService: GoalService,
    private translateService: TranslateService,
    private store: Store,
    private dialog: MatDialog
  ) {}

  loadDailyGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalActions.loadDailyGoal),
      withLatestFrom(this.store.select(UserSelectors.selectServiceReceiver)),
      switchMap(([{ date }, serviceReceiver]) => {
        if (!serviceReceiver) return of(UserActions.logout());
        return this.goalService.getGoals(serviceReceiver, date).pipe(
          map((goal: GoalItem) =>
            GoalActions.loadDailyGoalSuccess({
              goal,
            })
          ),
          catchError(() => of(GoalActions.loadDailyGoalFailure()))
        );
      })
    )
  );

  modifyGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalActions.modifyGoal),
      withLatestFrom(this.store.select(UserSelectors.selectServiceReceiver)),
      switchMap(([{ accept, goal }, serviceReceiver]) => {
        if (!serviceReceiver) return of(UserActions.logout());
        return this.goalService
          .modifyGoal(accept, goal.id, serviceReceiver)
          .pipe(
            map((_) => GoalActions.modifyGoalSuccess({ goal })),
            catchError((error) => of(GoalActions.modifyGoalFailure(error))),
            tap(() => {
              this.dialog
                .open(DialogSuccessComponent, {
                  data: {
                    hasDialogClose: true,
                    title: this.translateService.instant(
                      'GOAL.DIALOG.SUCCESS.TITLE'
                    ),
                    subtitle: this.translateService.instant(
                      accept
                        ? 'GOAL.DIALOG.SUCCESS.DESCRIPTION'
                        : 'GOAL.DIALOG.SUCCESS.NOT_DONE_DESCRIPTION'
                    )},
                  maxWidth: 'var(--dialog-max-width)',
                  width: 'var(--dialog-width)',
                  maxHeight: 'var(--dialog-height)',
                  panelClass: ['dialog-panel'],
                })
                .afterOpened()
                .pipe(delay(2000))
                .subscribe(() => {
                  this.dialog.closeAll();
                  this.store.dispatch(
                    GoalActions.loadDailyGoal({ date: new Date() })
                  );
                });
            })
          );
      })
    )
  );

  loadThreeMonthGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalActions.loadThreeMonthGoals),
      withLatestFrom(this.store.select(UserSelectors.selectServiceReceiver)),
      switchMap(([, serviceReceiver]) => {
        if (!serviceReceiver) return of(UserActions.logout());

        const current = new Date();
        current.setDate(1); // Avoid wrong dates in last month days
        const past = new Date(current);
        past.setMonth(past.getMonth() - 1);
        const next = new Date(current);
        next.setMonth(next.getMonth() + 1);

        return forkJoin([
          this.goalService.getGoalsByMonth(current, serviceReceiver),
          this.goalService.getGoalsByMonth(past, serviceReceiver),
          this.goalService.getGoalsByMonth(next, serviceReceiver),
        ]).pipe(
          map((goalsByMonth: GoalsByMonth[]) =>
            GoalActions.loadThreeMonthGoalsSuccess({
              goalsByMonth,
            })
          ),
          catchError(() => of(GoalActions.loadThreeMonthGoalsFailure()))
        );
      })
    )
  );
}
