import {
  ContactInfo,
  Service,
  ServiceCategory,
  ServiceInfo,
  ServiceLocationMarker,
  ServiceModality,
  ServiceReceiver,
  ServiceSchedule,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';
import {
  SavedServiceStatus,
  ServiceStatus,
} from '../../../../../shared/data-access/src';

export const loadAvailableServices = createAction('[Edercare] Load services');
export const loadAvailableServicesSuccess = createAction(
  '[Edercare] Load services success',
  props<{ hireServices: ServiceCategory[] }>()
);
export const loadAvailableServicesFailure = createAction(
  '[Edercare] Load services failure'
);

export const loadRecommendedServices = createAction(
  '[Edercare] Load recommended services'
);
export const loadRecommendedServicesSuccess = createAction(
  '[Edercare] Load recommended services success',
  props<{ services: ServiceInfo[] }>()
);
export const loadRecommendedServicesFailure = createAction(
  '[Edercare] Load recommended services failure'
);

export const setServiceFilterWord = createAction(
  '[Edercare] Set service filter word',
  props<{ word: string }>()
);
export const setServiceFilterCategory = createAction(
  '[Edercare] Set service filter category',
  props<{ categories: string[] }>()
);
export const loadServiceLocationMarkers = createAction(
  '[Edercare] Load service markers',
  props<{ ZIPCode: string; service: Service }>()
);
export const loadServiceLocationMarkersSuccess = createAction(
  '[Edercare] Load service markers success',
  props<{ markers: ServiceLocationMarker[] }>()
);
export const loadServiceLocationMarkersFailure = createAction(
  '[Edercare] Load service markers failure'
);

export const clearServiceLocationMarkers = createAction(
  '[Edercare] Clear service markers'
);

export const saveNewServiceModality = createAction(
  '[Edercare] Save new service modality',
  props<{ modality: ServiceModality; info: ServiceInfo }>()
);

export const saveNewServiceModalitySuccess = createAction(
  '[Edercare] Save new service modality success',
  props<{ ref: string; modality: ServiceModality }>()
);

export const saveNewServiceLocation = createAction(
  '[Edercare] Save new service location',
  props<{ ref: string; location: ServiceLocationMarker; selectedZIP: string }>()
);

export const saveNewServiceLocationSuccess = createAction(
  '[Edercare] Save new service location success',
  props<{ ref: string }>()
);
export const saveNewServiceLocationFailure = createAction(
  '[Edercare] Save new service location failure'
);

export const saveNewServiceSchedule = createAction(
  '[Edercare] Save new service schedule',
  props<{ ref: string; schedule: ServiceSchedule }>()
);

export const saveNewServiceScheduleSuccess = createAction(
  '[Edercare] Save new service schedule success',
  props<{ ref: string }>()
);

export const saveNewServiceScheduleFailure = createAction(
  '[Edercare] Save new schedule failure'
);

export const saveServiceReceiver = createAction(
  '[Edercare] Save service receiver',
  props<{ ref: string; serviceReceiver: ServiceReceiver }>()
);

export const saveServiceReceiverSuccess = createAction(
  '[Edercare] Save service receiver success',
  props<{ ref: string }>()
);

export const saveServiceReceiverFailure = createAction(
  '[Edercare] Save service receiver failure'
);

export const removeServiceFromCart = createAction(
  '[Edercare] Delete service',
  props<{ ref: string }>()
);

export const removeServiceFromCartSuccess = createAction(
  '[Edercare] Delete service success',
  props<{ ref: string }>()
);

export const removeServiceFromCartFailure = createAction(
  '[Edercare] Delete service failure'
);

export const loadCart = createAction('[Edercare] Load cart');
export const loadCartSuccess = createAction(
  '[Edercare] Load cart success',
  props<{ cart: Service[] }>()
);
export const loadCartFailure = createAction('[Edercare] Load cart failure');

export const emptyCart = createAction('[Edercare] Empty cart');
export const emptyCartSuccess = createAction('[Edercare] Empty cart success');

export const saveCart = createAction(
  '[Edercare] Save cart',
  props<{ cart: Service[] }>()
);
export const saveCartSuccess = createAction(
  '[Edercare] Save cart success',
  props<{ cart: Service[] }>()
);

export const saveCartFailure = createAction('[Edercare] Save cart failure');

export const loadServiceHistory = createAction(
  '[Edercare] Load service history',
  props<{ filters: SavedServiceStatus[]; page: number; pageSize: number }>()
);
export const loadServiceHistorySuccess = createAction(
  '[Edercare] Load service history success',
  props<{
    services: Service[];
    page: number;
    total: number;
    filters: SavedServiceStatus[];
  }>()
);
export const loadServiceHistoryFailure = createAction(
  '[Edercare] Load service history failure'
);

export const loadService = createAction(
  '[Edercare] Load service',
  props<{ ref: string }>()
);
export const loadServiceSuccess = createAction(
  '[Edercare] Load service success',
  props<{ service: Service }>()
);
export const loadServiceFailure = createAction(
  '[Edercare] Load service failure'
);

export const contact = createAction(
  '[Edercare] Contact',
  props<{ contactInfo: ContactInfo }>()
);
export const contactSuccess = createAction('[Edercare] Contact success');
export const contactFailure = createAction('[Edercare] Contact failure');
