import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { DynamicIconDirective } from '../../../../../utility/src/lib/directives/dynicon/dynicon.directive';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'nai-avatar',
  template: `
    <ng-template #Photo><img [src]="image" [alt]="initial" /></ng-template>
    <div class="avatar-content">
      <span *ngIf="!image; else Photo" class="font-size-bigger">
        {{ initial }}
      </span>
      <div class="edit-icon">
        <span naiDynIcon [path]="'assets/icons/edit.svg'"></span>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [DynamicIconDirective, CommonModule],
})
export class AvatarComponent {
  @Input() initial? = '';
  @Input() color? = '#ffb948';
  @Input() size: 'small' | 'medium' | 'large' | 'xl' | 'xxl' = 'medium';
  @Input() image?: string | null;
  @Input() editable = false;

  @HostBinding('class') get class() {
    return `avatar avatar--${this.size} ${
      this.editable ? 'avatar--editable' : ''
    }`;
  }

  @HostBinding('style') get style() {
    const color = this.image ? 'transparent' : this.color ?? '#ffb948';
    return `background-color: ${color}`;
  }
}
