export interface Plan {
  id: string;
  'active?': boolean;
}
export interface PlanResponse {
  'force-health-plan-configuration': boolean;
  'health-plan': Plan[];
}

export type PlanId =
  | 'educational-program'
  | 'physical-education'
  | 'neuro'
  | 'nutrition'
  | 'home-adaptation';

  export interface HealthPlanProgressResponse {
    'plan-progress': HealthPlanProgress[];
  }

  export interface HealthPlanProgress {
    id: string;
    title: string;
    currentCycle: number;
    totalCycles?: number | null;
    cycleName: string;
    steps: Step[];
  }

  export interface Step {
    name: string;
    currentProgress: number;
    totalProgress: number;
  }

  export interface HealthPlanSummaryResponse {
    'progress-history': HealthPlanSummaryItem[];
  }

  export interface HealthPlanSummaryItem {
    name: string;
    value: number;
  }
