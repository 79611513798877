import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { IonicModule, NavController } from '@ionic/angular';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';
import { EdercareActions, EdercareService } from '@nai-libs/edercare/data-access/src';
import { Service } from '@nai-libs/shared/data-access/src';
import { DialogComponent } from '@nai-libs/standalone/edercare-dialog';
import { SummaryItemComponent } from '@nai-libs/standalone/summary-item';
import { Store } from '@ngrx/store';

import { ServiceReceiver } from '@nai-libs/data-access';

import { Inject } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import { AppConfig } from '@nai-libs/data-access';
@Component({
  selector: 'nai-service-card',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    DynamicIconDirective,
    SummaryItemComponent,
    DatePipe,
    DialogComponent,
  ],
  template: `
    <div
      *ngIf="service"
      class="service-card"
      [ngStyle]="{ '--service-color': service.info.color }"
    >
      <header class="service-card__header">
          <div class="service-card__icon">
            <img
              *ngIf="service.info.icon; else defaultIcon"
              [src]="this.env.baseUrl + '/' + service.info.icon"
            />

            <ng-template #defaultIcon>
              <span naiDynIcon [path]="'assets/icons/handshake.svg'"></span>
            </ng-template>
          </div>
        <div>
          <h2 class="font-headline-6 service-card__title">{{ service.info.name }}</h2>
          <p class="service-card__description">
            Desde {{ service.info.price }}€ - {{ service.info.duration }}'
          </p>
        </div>

        <button
          class="service-card__trash-icon"
          (click)="onDelete(service.ref)"
          *ngIf="deletable"
        >
          <span naiDynIcon [path]="'assets/icons/trash.svg'"></span>
        </button>
      </header>
      <div class="service-card__content">
        <nai-summary-item [title]="'Modalidad'" [editable]="false">
          <ng-container [ngSwitch]="service.modality">
            <ng-container *ngSwitchCase="'online'"
              >Atención online</ng-container
            >
            <ng-container *ngSwitchCase="'center-on-site'">Centro</ng-container>
            <ng-container *ngSwitchCase="'home-on-site'">Domicilio</ng-container>
          </ng-container>
        </nai-summary-item>
        <nai-summary-item
          *ngIf="service.location"
          [style]="'edercare'"
          [title]="'Centro'"
          [editable]="true"
          (clickEdit)="onEditLocation(service.ref)"
        >
          {{ service.location.name }}
        </nai-summary-item>
        <nai-summary-item
          [style]="'edercare'"
          [style]="'edercare'"
          [title]="'Fecha y hora'"
          [editable]="editable"
          (clickEdit)="onEditSchedule(service.ref)"
        >
          <span
            >Desde el {{ service.schedule.startDate | date : 'longDate' }}</span
          >
          <span>{{
            service.schedule.endDate
              ? 'Hasta el ' + (service.schedule.endDate | date : 'longDate')
              : 'Sin fecha fin'
          }}</span>
          <span>{{ service.schedule.weekdays }}</span>
          <ng-container [ngSwitch]="service.schedule.time">
            <ng-container *ngSwitchCase="'morning'"
              >Horario de mañana (de 08:00 a 14:00)</ng-container
            >
            <ng-container *ngSwitchCase="'afternoon'"
              >Horario de tarde (de 14:00 a 20:00)</ng-container
            >
            <ng-container *ngSwitchCase="'night'"
              >Horario de noche (de 20:00 a 08:00)</ng-container
            >
          </ng-container>
        </nai-summary-item>
        <nai-summary-item
          [style]="'edercare'"
          [title]="'Persona beneficiaria'"
          [editable]="false"
        >
          <span
            *ngIf="serviceReceiver; else noData"
            >{{ serviceReceiver.identifier.name }}
            {{ serviceReceiver.identifier['first-surname'] }}</span
          >
          <ng-template #noData>
            <span style="color: var(--color-error-60);">Sin información</span>
          </ng-template>
        </nai-summary-item>
        <nai-summary-item
          [style]="'edercare'"
          [title]="'Presupuesto'"
          [editable]="false"
        >
          Desde {{ service.info.price }}€
        </nai-summary-item>
      </div>
    </div>
  `,
  styleUrls: ['./service-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCardComponent {
  @Input() service?: Service;
  @Input() editable = true;
  @Input() deletable = true;
  @Input() serviceReceiver?: ServiceReceiver | null;

  constructor(
    private navCtrl: NavController,
    private store: Store,
    private dialog: MatDialog,
    public edercareService: EdercareService,
    @Inject(APP_CONFIG) public env: AppConfig
  ) {}
  onEditLocation(ref: string) {
    this.navCtrl.navigateBack(this.edercareService.getRoute('service/' + ref +'/location'));
  }
  onEditSchedule(ref: string) {
    this.navCtrl.navigateBack(this.edercareService.getRoute('service/' + ref +'/schedule'));
  }

  onDelete(ref: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        image: 'assets/icons/alert-error.svg',
        title: '¿Deseas eliminar el servicio de tu cesta?',
        actions: [
          {
            id: 'delete',
            text: 'Confirmar',
            style: 'fill',
          },
          {
            id: 'cancel',
            text: 'Cancelar',
            style: 'outline',
          },
        ],
      },
      maxWidth: 'var(--dialog-max-width)',
      width: 'var(--dialog-width)',
      maxHeight: 'var(--dialog-height)',
      panelClass: ['dialog-panel', 'dialog-edercare'],
    });

    dialogRef.afterClosed().subscribe((action) => {
      switch (action) {
        case 'delete':
          this.store.dispatch(EdercareActions.removeServiceFromCart({ ref }));
          break;
        default:
          break;
      }
    });
  }
}
