export type ServiceModality = 'online' | 'center-on-site' | 'home-on-site';
export const SERVICE_MODALITY: { [key in ServiceModality]: string } = {
  online: "Atención online",
  "center-on-site": "Centro",
  "home-on-site": "A domicilio",
};

export type ServiceStatus =
  | 'active'
  | 'pending'
  | 'ended'
  | 'inactive'
  | 'cart';
export type SavedServiceStatus = Exclude<ServiceStatus, 'cart'>;
export const SERVICE_STATUS: { [key in ServiceStatus]: string } = {
  cart: 'En el carrito',
  active: 'Activo',
  pending: 'A la espera',
  ended: 'Finalizado',
  inactive: 'No activado',
};
export const SAVED_SERVICE_STATUS: { [key in SavedServiceStatus]: string } = {
  active: 'Activo',
  pending: 'A la espera',
  ended: 'Finalizado',
  inactive: 'No activado',
};

export const SERVICE_STATUS_COLOR: { [key in ServiceStatus]: string } = {
  cart: "var(--color-primary)",
  active: "var(--color-success-80)",
  pending: "var(--color-custom-yellow-80)",
  ended: "var(--color-neutral-variant-80)",
  inactive: "var(--color-error-80)",
};

export interface ServiceCategory {
  family: string;
  code: string;
  color: string;
  icon: string;
  services: ServiceInfo[];
}

export interface ServiceInfo {
  code: string;
  name: string;
  description: string;
  profesional: string;
  duration: string;
  color: string;
  family: string;
  icon: string;
  price: number;
  modalities: {
    [key in ServiceModality]: { price: number; description: string };
  };
}
export interface ServiceLocationMarkerResponse {
  markers: ServiceLocationMarker[];
  'online-services': ServiceInfo[];
}

export interface ServiceLocationMarker {
  id: string;
  CP: string;
  name: string;
  phone: string;
  email: string;
  address: string;
  latLong: string;
  services: {
    id: string;
    price: number;
  }[];
  zone: number;
}
export interface ServiceFilter {
  word?: string;
  categories?: string[];
}

export interface ServiceSchedule {
  type: 'specific' | 'periodic';
  startDate: string;
  endDate?: string;
  frequency?: 'week' | 'two-weeks' | 'month';
  weekdays?: Array<'L' | 'M' | 'X' | 'J' | 'V' | 'S' | 'D'>;
  time: 'morning' | 'afternoon' | 'night';
}

export interface Service {
  status: ServiceStatus;
  ref: string;
  date?: string;
  selectedZIP: string;
  info: ServiceInfo;
  modality: ServiceModality;
  location: ServiceLocationMarker;
  schedule: ServiceSchedule;
}

export interface ContactInfo {
  email: string;
  phone: string;
  message: string;
}

export interface ServiceHistoryResponse {
  totalOffers: number;
  offers: Service[];
}
