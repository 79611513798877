export const addOrUpdateArray = (
  existingArray: Array<any>,
  newArray: Array<any>,
  idKey: string
) => {
  const returnArray: Array<any> = JSON.parse(JSON.stringify(existingArray));
  newArray.forEach((newObj) => {
    const index = returnArray.findIndex(
      (obj) => obj[idKey] === newObj[idKey]
    );
    if (index !== -1) {
      returnArray[index] = newObj;
    } else {
      returnArray.push(newObj);
    }
  });

  return returnArray;
};
