import {
  ArticleResource,
  FormativeAreaInfo,
  Article,
  GlossaryWord,
  
  ArticleResponse,
  FormativeAreaResponse,
} from '@nai-libs/data-access';
import { createAction, props } from '@ngrx/store';

export const loadSuggestedArticles = createAction(
  '[Article] Load suggested articles'
);
export const loadSuggestedArticlesSuccess = createAction(
  '[Article] Load suggested articles success',
  props<{ articles: Article[] }>()
);
export const loadSuggestedArticlesFailure = createAction(
  '[Article] Load suggested articles error'
);

export const loadAreaInfoList = createAction(
  '[Article] Load area info list',
  props<{ forceFetch?: boolean }>()
);
export const loadAreaInfoListSuccess = createAction(
  '[Article] Load area info list success',
  props<{ areaInfoList: FormativeAreaInfo[] }>()
);
export const loadAreaInfoListFailure = createAction(
  '[Article] Load area info list error',
  props<{ error: string }>()
);

export const loadArea = createAction(
  '[Article] Load Area',
  props<{ id: string }>()
);
export const loadAreaSuccess = createAction(
  '[Article] Load Area Success',
  props<{ area: FormativeAreaResponse }>()
);
export const loadAreaFailure = createAction('[Article] Load Area Failure');

export const loadArticle = createAction(
  '[Article] Load Article',
  props<{ id: string; area: string }>()
);
export const loadArticleSuccess = createAction(
  '[Article] Load Article Success',
  props<{ articles: Article[] }>()
);
export const loadArticleFailure = createAction(
  '[Article] Load Article Failure'
);

export const loadArticleResources = createAction(
  '[Article] Load Article resources',
  props<{ id: string; area: string }>()
);
export const loadArticleResourcesSuccess = createAction(
  '[Article] Load Article resources Success',
  props<{ resources: ArticleResource[] }>()
);
export const loadArticleResourcesFailure = createAction(
  '[Article] Load Article resources Failure'
);

export const loadGlosaryWord = createAction(
  '[Article] Load glossary word',
  props<{ NANDACode: string; articleCode: string; word: string }>()
);
export const loadGlosaryWordSuccess = createAction(
  '[Article] Load glossary word Success',
  props<{ glossaryWord: GlossaryWord }>()
);
export const loadGlosaryWordFailure = createAction(
  '[Article] Load glossary word Failure'
);

export const markArticleAsRead = createAction(
  '[Article] Mark article as read',
  props<{ NANDACode: string; articleCode: string }>()
);
export const markArticleAsReadSuccess = createAction(
  '[Article] Mark article as read Success'
);
export const markArticleAsReadFailure = createAction(
  '[Article] Mark article as read Failure',
  props<{ error: string }>()
);

export const rateArticle = createAction(
  '[Article] Rate article',
  props<{
    NANDACode: string;
    articleCode: string;
    rating: number;
  }>()
);
export const rateArticleSuccess = createAction(
  '[Article] Rate article Success',
  props<{ NANDACode: string; articleCode: string }>()
);
export const rateArticleFailure = createAction(
  '[Article] Rate article Failure',
  props<{ error: string }>()
);

export const likeArticle = createAction(
  '[Article] Like article',
  props<{ NANDACode: string; articleCode: string; like: boolean }>()
);

export const likeArticleSuccess = createAction(
  '[Article] Like article success',
  props<{ NANDACode: string; articleCode: string }>()
);

export const likeArticleFailure = createAction(
  '[Article] Like article failure',
  props<{ error: string }>()
);
